angular.module("services")
  .factory('ItemsService', [ '$rootScope', 'Restangular', '$resource', 'hostAddress', '$http',
    function ($rootScope, Restangular, $resource, hostAddress, $http) {
      var factory = {};

      var itemsResource = Restangular.all('items');

      factory.loadItems = function(callback) {
        var items = itemsResource.getList().$object;
        callback(items);
      };

      factory.getItem = function(itemId){
       return Restangular.one("items", itemId).get();
      };

      factory.getItemByCodes = function(modelCode, articleCode){
        return Restangular.one('item_by_codes').get({model_code: modelCode, article_code: articleCode});
      };

      factory.putItem = function(formData, itemId){
        return itemsResource.withHttpConfig({transformRequest: angular.identity})
          .customPUT(formData, itemId, undefined, {'Content-Type': undefined});
      };

      factory.postItem = function(formData){
        return itemsResource.withHttpConfig({transformRequest: angular.identity})
          .post(formData, {}, {'Content-Type': undefined});
      };

      factory.deleteItem = function(itemId){
        return Restangular.one("items", itemId).remove();
      };

      //$.param() jQuery
      factory.param = function(obj) {
        var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
        for(name in obj) {
          value = obj[name];

          if(value instanceof Array) {
            for(i=0; i<value.length; ++i) {
              subValue = value[i];
              fullSubName = name + '[]';
              innerObj = {};
              innerObj[fullSubName] = subValue;
              query += factory.param(innerObj) == ''? '' : factory.param(innerObj) + '&';
            }
          }
          else if(value instanceof Object) {
            for(subName in value) {
              subValue = value[subName];
              fullSubName = name + '[' + subName + ']';
              innerObj = {};
              innerObj[fullSubName] = subValue;
              query += factory.param(innerObj) == ''? '' : factory.param(innerObj) + '&';
            }
          }
          else if(value !== undefined && value !== null){
            query += name + '=' + value + '&';
          }

        }

        return query.length ? query.substr(0, query.length - 1) : query;
      };


      factory.getFilteredItems = function(){
        var resource = $resource( hostAddress + '/api' +'/items?:q',
          {
            page : "@page",
            per_page : "@per_page",
            q : "@q"
          },
          {
            'get':  {
              method:'GET',
              /*transformRequest: $http.defaults.transformRequest.concat([function(data,headers){
                //Doesn't work !*@$$"°!°$"
                return data;
              }]),*/
              isArray: true

            }
          });
        return resource;
      };

      factory.createEmptyCouplesinItem = function(item){
        if (item.garment_accessories.length == 0) {
          item.garment_accessories = [
            {
              "garment_accessory_id": '',
              "garment_accessory_position_id": ''
            }
          ];
        }

        if (item.finishings.length == 0) {
          item.finishings = [
            {
              "finishing_id": '',
              "decoration_position_id": ''
            }
          ];
        }

        if (item.adornments.length == 0) {
          item.adornments = [
            {
              //"id": '',
              "adornment_id": '',
              "decoration_position_id": ''
            }
          ];
        }

        if (item.closures.length == 0) {
          item.closures = [
            {
              "closure_id": '',
              "closure_position_id": ''
            }
          ];
        }

        if (item.decoration_patterns.length == 0) {
          item.decoration_patterns = [
            {
              "decoration_pattern_id": '',
              "decoration_position_id": ''
            }
          ];
        }

        if (item.pockets.length == 0) {
          item.pockets = [
            {
              "pocket_id": '',
              "pocket_position_id": ''
            }
          ];
        }

        if (item.yarns.length == 0) {
          item.yarns = [
            {
              "yarn_id": ''
            }
          ];
        }

        if (item.stitches.length == 0 ) {
          item.stitches = [
            {
              "stitch_id": ''
            }
          ];
        }

        if (item.textile_fibres.length == 0) {
          item.textile_fibres = [
            {
              "textile_fibre_id": '',
              "percentage": ''
            }
          ]
        }
      };

      return factory;
    }
  ]);
