angular.module('controllers')
  .controller('MassUploadCtrl', ["$rootScope", "$scope", "ItemsService", "MassUploadService",
    function($rootScope, $scope, ItemsService, MassUploadService) {

      $scope.files = [];
      $scope.progress = 0; // in percentage
      /**
       * Statuses:
       * errors - show errors list only
       * new-upload - user has selected some files
       * uploading - during the upload
       * upload-results - after the upload has ended
       */
      $scope.status = 'errors';
      $scope.uploaded = 0;
      $scope.notUploaded = 0;
      $scope.showErrors = true;
      $scope.leftBarDragOver = false;
      $scope.tableDragOver = false;
      $scope.massUploadErrors = [];
      $scope.busy = true;
      $scope.filters = {
        model_code_cont: '',
        article_code_cont: ''
      };

      window.addEventListener("dragover",function(e){
        e = e || event;
        e.preventDefault();
      },false);
      window.addEventListener("drop",function(e){
        e = e || event;
        e.preventDefault();
      },false);

      MassUploadService.getErrors().then(function(data) {
        $scope.massUploadErrors = data.data;
        $scope.busy = false;
      });

      var incrementProgress = function (type) {
        // don't judge me for this
        if ($scope.progress < 100) $scope.progress += 100/$scope.files.length;
        else $scope.progress = 100/$scope.files.length;

        if (type === 'uploaded') $scope.uploaded += 1;
        else $scope.notUploaded += 1;

        if ($scope.files.length === $scope.uploaded + $scope.notUploaded) {
          $scope.progress = 0;
          $scope.status = 'upload-results';
        }
      };

      /**
       * Adds a new item to the massUploadErrors array in local storage; if the specified filename
       * already has a error it updates it
       * @param {string} file - File
       * @param {string} error - error to attach in status
       * @param {number} id - id of the element (if determined)
       * @param {string} side - which photo has caused the error
       */
      var appendError = function (file, error, id, side) {
        var index = $scope.massUploadErrors.findIndex(function (error) {
          return error.file_name === file.name;
        });
        if (index === -1)
          MassUploadService.postError({file_name: file.name, status: error, file_id: id, side: side, webkit_relative_path: file.webkitRelativePath}).then(function(data) {
            $scope.massUploadErrors.push(data.data);
          });
        else {
          MassUploadService.putError($scope.massUploadErrors[index].id, {
            file_id: id,
            status: error,
            side: side,
            webkitRelativePath: file.webkitRelativePath
          }).then(function(data) {
            $scope.massUploadErrors[index] = data.data;
          });
        }
      };

      $scope.removeError = function (filename) {
        var index = $scope.massUploadErrors.findIndex(function (error) {
          return error.file_name === filename;
        });
        if (index !== -1) MassUploadService.deleteError($scope.massUploadErrors[index].id).then(function(data) {
          $scope.massUploadErrors.splice(index, 1);
        });

      };

      /**
       * Set the status of the item
       * @param {number} fileIndex - The index of the file.
       * @param {string} status -  - loading: sta facendo la GET o la PUT
                                   - not-valid: modelCode e articleCode non estraibili, nome file non valido
                                   - not-found: la GET ha dato 404
                                   - already-uploaded: la GET ha dato un item che ha già quella immagine
                                   - error: c'è stato un errore nella PUT (500 o 422)
                                   - photo-type-not-valid: lmao
                                   - success: la PUT è andata a buon fine.
       */
      var setStatus = function(fileIndex, status) {
        $scope.files[fileIndex].status = status;
        if (status !== 'loading') $scope.files[fileIndex].date = new Date();
      };

      var putItem = function (fileIndex) {
        ItemsService.getItemByCodes($scope.files[fileIndex].modelCode, $scope.files[fileIndex].articleCode).then(
          function (data) {
            var object = {};
            switch ($scope.files[fileIndex].imageType) {
              case 'f':
                if (data.front_image_original_file_name) {
                  setStatus(fileIndex, 'already-uploaded');
                  $scope.removeError($scope.files[fileIndex].file_name);
                  incrementProgress();
                  return;
                }
                object.front_image = $scope.files[fileIndex];
                break;
              case 'r':
                if (data.back_image_original_file_name) {
                  setStatus(fileIndex, 'already-uploaded');
                  $scope.removeError($scope.files[fileIndex].file_name);
                  incrementProgress();
                  return;
                }
                object.back_image = $scope.files[fileIndex];
                break;
              case 'det':
                if (data.details_image_original_file_name) {
                  setStatus(fileIndex, 'already-uploaded');
                  $scope.removeError($scope.files[fileIndex].file_name);
                  incrementProgress();
                  return;
                }
                object.details_image = $scope.files[fileIndex];
                break;
              case 'lat': // lateral images go in sketch because
                if (data.sketch_image_original_file_name) {
                  setStatus(fileIndex, 'already-uploaded');
                  $scope.removeError($scope.files[fileIndex].file_name);
                  incrementProgress();
                  return;
                }
                object.sketch_image = $scope.files[fileIndex];
                break;
              default:
                setStatus(fileIndex, 'photo-type-not-valid');
                appendError($scope.files[fileIndex], 'photo-type-not-valid', data.id, $scope.files[fileIndex].imageType);
                incrementProgress();
                return;
            }
            ItemsService.putItem(Object.toFormData(object), data.id).then(
              function () {
                setStatus(fileIndex, 'success');
                $scope.removeError($scope.files[fileIndex].file_name);
                incrementProgress('uploaded');
              },
              function () {
                setStatus(fileIndex, 'error');
                appendError($scope.files[fileIndex], 'error', data.id, $scope.files[fileIndex].imageType);
                incrementProgress();
              }
            )
          },
          function () {
            setStatus(fileIndex, 'not-found');
            appendError($scope.files[fileIndex], 'not-found');
            incrementProgress();
          }
        )
      };

      var extractCodes = function(file_name) {
        var itemInfo = file_name.split('-');
        return {
          modelCode: itemInfo[4],
          articleCode: itemInfo[5],
          imageType: itemInfo[6] && itemInfo[6].split('.')[0]
        }
      };

      $scope.submitItems = function() {
        $scope.status = 'uploading';
        for (var i = 0; i < $scope.files.length; ++i) {
          setStatus(i, 'loading');
          var codes = extractCodes($scope.files[i].name);
          $scope.files[i].modelCode = codes.modelCode;
          $scope.files[i].articleCode = codes.articleCode;
          $scope.files[i].imageType = codes.imageType;
          // if I have codes get the item id
          if ($scope.files[i].modelCode && $scope.files[i].articleCode) {
            putItem(i);
          } else {
            setStatus(i, 'not-valid');
            appendError($scope.files[i], 'not-valid');
            incrementProgress();
          }
        }
      };

      $scope.filesChanged = function(files) {

        var auxFiles = $scope.files.concat($scope.files, Array.from(files));

        // remove duplicates and not images
        $scope.files = auxFiles.filter(function(item, pos) {
          return auxFiles.map(function (obj) { return obj.name }).indexOf(item.name) === pos && item.type.indexOf('image/') === 0;
        });

        for (var i = 0; i < $scope.files.length; ++i) {
          for (var j = 0; j < $scope.massUploadErrors.length; ++j) {
            if ($scope.files[i].name === $scope.massUploadErrors[j].file_name) setStatus(i, $scope.massUploadErrors[j].status);
          }
        }

        if ($scope.files.length) {
          $scope.status = 'new-upload';
          $scope.showErrors = false;
        }

        $scope.$apply(); // yolo
      };

      var leftBarEnterCounter = 0;
      var tableEnterCounter = 0;

      $scope.onDrop = function(event) {
        $scope.leftBarDragOver = false;
        $scope.tableDragOver = false;
        leftBarEnterCounter = 0;
        tableEnterCounter = 0;
        $scope.filesChanged(event.dataTransfer.files);
      };

      $scope.onDragOver = function(value, element) {
        if (element === 'left-bar') {
          if (value) leftBarEnterCounter++;
          else leftBarEnterCounter--;
          $scope.leftBarDragOver = !!leftBarEnterCounter;
        }
        if (element === 'table') {
          if (value) tableEnterCounter++;
          else tableEnterCounter--;
          $scope.tableDragOver = !!tableEnterCounter;
        }
        $scope.$apply();
      };

      $scope.deleteFile = function(index) {
        $scope.files.splice(index, 1);
      };

      $scope.reset = function() {
        $scope.files = [];
        $scope.status = 'errors';
        $scope.uploaded = 0;
        $scope.notUploaded = 0;
        $scope.showErrors = true;
      };

      $scope.toggleShowErrors = function(value) {
        $scope.showErrors = value;
      };

      $scope.filteredMassUploadErrors = function() {
        if ($scope.filters.model_code_cont.length || $scope.filters.article_code_cont.length)
          return $scope.massUploadErrors.filter(function(error) {
            var codes = extractCodes(error.file_name);
            return ($scope.filters.model_code_cont.length && codes.modelCode && codes.modelCode.includes($scope.filters.model_code_cont)) ||
                   ($scope.filters.article_code_cont.length && codes.articleCode && codes.articleCode.includes($scope.filters.article_code_cont))
          });
        else
          return $scope.massUploadErrors;
      };

      function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var edge = ua.indexOf('Edge/');

        if (msie > 0 || trident > 0 || edge > 0) {
          // IE 10 or older => return version number
          return true;
        }

        // other browser
        return false;
      }
      $rootScope.isIE = detectIE();

      $scope.isOpen = false;
      $scope.waitToKillPopup = function(){
        $scope.isOpen = !$scope.isOpen;
        var clickFunction = function(){
          document.removeEventListener('click', clickFunction, false);
          if($scope.isOpen){
            document.getElementsByClassName('mass-upload__new-upload')[0].click();
          }
        };

        var timeOutTime = $rootScope.isIE? '200' : '100'; //IE needs more time

        setTimeout(function(){
          if($scope.isOpen) {
            document.addEventListener("click", clickFunction, false);
          }
        },timeOutTime)
      };
    }
  ]);



