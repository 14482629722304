angular
  .module('controllers')
  .controller('ItemsCtrl', ["$rootScope", "$scope", "$state", "ItemsService", '$filter', '$window', 'Restangular',
    function($rootScope, $scope, $state, ItemsService, $filter, $window, Restangular) {

      $scope.items = [];
      if(!$rootScope.itemsFilterObj){
        $rootScope.itemsFilterObj = {
          q : {
            s: ['collection_year desc', 'collection_id asc', 'model_code asc'],
            model_code_cont : '',
            article_code_cont : '',
            front_image_original_file_name_present : null,
            look_look_category_id_present: null,
            revised_eq : null,
            catalogue_eq: null,
            compositions_textile_fibre_id_in :[],
            collection_season_id_in : [],
            colors_id_in : [],
            gender_id_in:[],
            collection_clothing_brand_customer_id_in : [],
            collection_year_in: [],
            collection_clothing_brand_id_in : [],
            gauges_id_in : [],
            look_look_category_id_in : [],
            yarns_id_in : [],
            secondary_materials_secondary_material_category_id_in : [],
            stitches_stitch_category_id_in : [],
            decoration_patterns_decoration_pattern_category_id_in : [],
            adornments_adornment_category_id_in : [],
            finishings_finishing_category_id_in : []
          }
        };
      }


      angular.element($window).on('resize', function() {
        if($state.is('items')){
          if(calculateItems() > $scope.per_page){
            $scope.per_page = (calculateItems() < 16) ? 16 : calculateItems();
            $scope.page = 1;
            $scope.stopScrolling = false;
            $scope.items = [];
            $scope.getItems();
          }
        }
      });

      var calculateItems = function(){
        var itemsContainerStyle = window.getComputedStyle(document.getElementById('items-container'), null);
        var height = window.innerHeight;
        var width  = parseInt(itemsContainerStyle.getPropertyValue("width"));

        var row = Math.floor(width / 200);
        var column = Math.ceil(height / 130); //Item with no image

        return row * column;
      };

      //Load items handler
      $scope.busy = false;
      $scope.page = 1;
      $scope.per_page = (calculateItems() < 16) ? 16 : calculateItems();
      $scope.getItems = function(){
        //Synch callback
        if ($scope.busy) return;

        $scope.busy = true;
        var serializedQ = ItemsService.param($scope.itemsFilterObj);
        ItemsService.getFilteredItems().get(
          {
            page : $scope.page,
            per_page : $scope.per_page,
            q : serializedQ  //No vuoto
          },
          function success(data,responseHeaders){

            $scope.total = responseHeaders('total');

            if (data.length === 0) {
              $scope.stopScrolling = true;
            }

            //Push new items
            angular.forEach(data, function (e) {
              $scope.items.push(e);
            });
            $scope.page += 1;
            $scope.busy = false;
          },
          function error(err){
            $scope.busy = false;
            console.log(err);
          }
        );
      };
      //First call
      $scope.getItems();

      $scope.$watch(function(){return $rootScope.itemsFilterObj;}, function(newVal, oldVal){
        if(newVal != oldVal && newVal !== undefined){
          $scope.page = 1;
          $scope.stopScrolling = false;
          $scope.items = [];
          calculateItems();
          $scope.getItems();
        }
      },true);

      var itemUpdatedFunction = $rootScope.$on("itemUpdated", function(event, args) {
        $scope.items.forEach(function(item, index){
          if(item.id === args.item.id) {
            $scope.items[index] = Restangular.copy(args.item);
          }
        });
      });
      $scope.$on('$destroy', itemUpdatedFunction);


    }
  ]);
