(function() {
  'use strict';

  angular.module('services', []);
  angular.module('controllers', []);
  angular.module('directives', []);
  angular.module('application', [
    'ui.router',
    'ct.ui.router.extras.core',
    'ct.ui.router.extras.sticky',
    'ngAnimate',

    'ngFileUpload',
    'ngResource',

    'services',
    'controllers',
    'directives',
    'ui.select',

    //foundation
    'foundation',
    'foundation.dynamicRouting',
    'foundation.dynamicRouting.animations',

    'ngSanitize',
    'ipCookie',
    'ng-token-auth',
    'ngStorage',
    'pascalprecht.translate',
    'restangular',
    'infinite-scroll'
  ])
    .config(config)
    .run(run)
  ;

  config.$inject = ['$urlRouterProvider', '$provide', '$stateProvider','$authProvider', 'RestangularProvider', 'hostAddress', '$httpProvider'];
  function config($urlProvider, $provide, $stateProvider, $authProvider, RestangularProvider, hostAddress, $httpProvider) {

    //Encode characters in query string parameters for search
    $httpProvider.interceptors.push(function($q) {
      return {
        'request': function(config) {
          if(config.url.indexOf('items?q') !== -1){
            config.url = (config.url).replace('+', encodeURIComponent('+') );
            config.url = (config.url).replace('_', encodeURIComponent('_') );
          }
          return config;
        }
      };
    });


    $stateProvider
      .state('landing', {
        url: "/",
        templateUrl: "templates/landing.html"
      })
      .state('forgot-password', {
        url: "/forgot_password",
        templateUrl: "templates/forgot-password.html"
      })
      .state('items', {
        url: "/items",
        sticky: true,
        views: {
          'items': {
            templateUrl: "templates/items.html"
          }
        },
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      })
      .state('new-item', {
        url: "/items/new-item",
        templateUrl: "templates/new-item.html",
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      })
      .state('item', {
        url: "/items/:item",
        templateUrl: "templates/item.html",
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      })
      .state('mass-upload', {
        url: "/mass-upload",
        sticky: true,
        views: {
          'mass-upload': {
            templateUrl: "templates/mass-upload.html"
          }
        },
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      })
      .state('catalogue', {
        url: "/catalogue",
        sticky: true,
        views: {
          'catalogue': {
            templateUrl: "templates/catalogue.html"
          }
        },
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      })
      .state('catalogue-item', {
        url: "/catalogue/:item",
        templateUrl: "templates/catalogue-item.html",
        resolve: {
          auth: function($auth) {
            return $auth.validateUser();
          }
        }
      });
    $urlProvider.otherwise('/items');

    $authProvider.configure({
      apiUrl: hostAddress + '/api',
      storage: 'localStorage'
    });

    RestangularProvider.setBaseUrl(hostAddress+'/api');

    //Airbrake code
    if(location.href.match(/.*staging.*/) || location.href.match(/www\..*/)){
      window.airbrake = new airbrakeJs.Client({});
      window.airbrake.setProject("bebb85b5941fa28e6b1df8dc432cdb57","bebb85b5941fa28e6b1df8dc432cdb57");
      window.airbrake.setHost("https://errors.moku.io");
      $provide.decorator("$exceptionHandler", ['$delegate', function($delegate) {
        return function (exception, cause) {

          window.airbrake.notify({
            error : {
              message : exception.toString(),
              stack : exception.stack
            },
            params : {

            }
          });
          $delegate(exception, cause);
        }
      }])
    }
  }

  run.$inject = ['$window', '$location', '$rootScope', '$localStorage', '$translate', "$state", 'hostAddress'];
  function run($window, $location, $rootScope, $localStorage, $translate, $state, hostAddress) {

    //FastClick.attach(document.body);

    $rootScope.$storage = $localStorage;
    $rootScope.baseUrl = hostAddress+'/api';

    //Set language
    var language = $window.navigator.userLanguage || $window.navigator.language;
    if(!$rootScope.$storage.profileUser){
      $rootScope.language = language.split('-')[0];
      $translate.use(language.split('-')[0]);
    }
    else{
      $rootScope.language = $rootScope.$storage.profileUser.locale? $rootScope.$storage.profileUser.locale : language.split('-')[0];
      $translate.use($rootScope.language);
    }

    var notRequireLoginPages = ['/', '/forgot_password'];
    $rootScope.$on('$locationChangeStart', function (event, next, current) {
      //redirect to login page if not logged in
      if (!$rootScope.$storage.profileUser.id   && (notRequireLoginPages.indexOf($location.path()) === -1)) {
        $location.path('/');
      }
      else{
        //If role == sales redirect user
        if(($location.path().indexOf('/items') !== -1) && ($rootScope.$storage.profileUser && $rootScope.$storage.profileUser.role === 'sales')){
          //$state.go("catelogue");
          $location.path('/catalogue');
        }
        //Redirect if user is logged
        if(($location.path() === '/' || $location.path() === '/forgot_password') && $rootScope.$storage.profileUser.id > 0){
          $state.go("items");
        }
      }
    });

    //User's token has expired
    $rootScope.$on('auth:session-expired', function(ev) {
      $state.go('landing');
    });

    //Prevent backspace from going back
    angular.element(document).unbind('keydown').bind('keydown', function (event) {
      var doPrevent = false;
      if (event.keyCode === 8) {
        var d = event.srcElement || event.target;
        if ((d.tagName.toUpperCase() === 'INPUT' &&
            (
            d.type.toUpperCase() === 'TEXT' ||
            d.type.toUpperCase() === 'PASSWORD' ||
            d.type.toUpperCase() === 'FILE' ||
            d.type.toUpperCase() === 'SEARCH' ||
            d.type.toUpperCase() === 'EMAIL' ||
            d.type.toUpperCase() === 'NUMBER' ||
            d.type.toUpperCase() === 'DATE' )
          ) ||
          d.tagName.toUpperCase() === 'TEXTAREA') {
          doPrevent = d.readOnly || d.disabled;
        }
        else {
          doPrevent = true;
        }
      }

      if (doPrevent) {
        event.preventDefault();
      }
    });

  }

})();
