angular.module('controllers')
  .controller('CatalogueItemCtrl', ["$rootScope", "$scope", "$state", "ItemsService", "$timeout", "$stateParams",
    function($rootScope, $scope, $state, ItemsService, $timeout, $stateParams) {

      var itemId = $stateParams.item;
      $scope.bool = true;
      $scope.item = {};
      $scope.photos = [];

      $scope.getItem = function () {
        ItemsService.getItem(itemId).then(function(item){
          $scope.item = item;
          $scope.update();
          $scope.photoToShow = $scope.item.front_image_medium;

          if ($scope.front_image_medium !== "" && $scope.back_image_medium !== "" && $scope.details_image_medium !== "") {
            $scope.photos = [
              {
                thumb: $scope.item.front_image_medium,
                large: $scope.item.front_image_large
              },
              {
                thumb: $scope.item.back_image_medium,
                large: $scope.item.back_image_large
              },
              {
                thumb: $scope.item.details_image_medium,
                large:$scope.item.details_image_large
              }
            ];
            // At first, the template starts with the first element as inactive, and the last one holding the arrow
            $scope.inactiveMediumPhoto = 0;
            $scope.arrowWieldingPhoto = 2;
          }

          if ($scope.item.front_image_medium !== "" && $scope.item.back_image_medium !== "" && $scope.item.details_image_medium == "") {
            $scope.photos = [
              {
                thumb: $scope.item.front_image_medium,
                large: $scope.item.front_image_large
              },
              {
                thumb: $scope.item.back_image_medium,
                large: $scope.item.back_image_large
              }
            ];
          }

          if ($scope.item.front_image_medium !== "" && $scope.item.back_image_medium == "" && $scope.item.details_image_medium == "") {
            $scope.photos = [
              {
                thumb: $scope.item.front_image_medium,
                large: $scope.item.front_image_large
              }
            ];
          }

          if ($scope.item.front_image_medium == "" && $scope.item.back_image_medium == "" && $scope.item.details_image_medium == "") {
            $scope.photos = [];
          }

        });
      };

      if(!(angular.equals({}, $rootScope.resources))){
        $scope.getItem();
      }

      var resourceLoaded = $rootScope.$on('resource:loaded', function() {
        $scope.getItem();
      });

      var unbind = [resourceLoaded];
      $scope.$on('$destroy', function () {
        unbind.forEach(function (fn) {
          fn();
        });
      });


      $scope.imageActive = 0;

      $scope.changePhotosSmall = function() {
        if ($scope.photos.length == 2) {
          if ($scope.imageActive == 0) {
            return $scope.imageActive = 1;
          } else {
            return $scope.imageActive = 0
          }
        }

        if ($scope.photos.length == 3) {
          if ($scope.imageActive == 0) {
            return $scope.imageActive = 1;
          }
          if ($scope.imageActive == 1) {
            return $scope.imageActive = 2;
          }
          if ($scope.imageActive == 2) {
            return $scope.imageActive = 0;
          }
        }
      };


      $scope.changePhotosMediumUp = function () {

        /*
         Set the previous item as inactive; this operation is a reduced version of
         (index + 3 - 1) % 3, which cyclically subtracts 1 to the current index while keeping it between 0 and 2
          */
        $scope.inactiveMediumPhoto = ($scope.inactiveMediumPhoto + 2) % 3;

        // The item that should wield the arrow is determined depending on which item is inactive
        if($scope.inactiveMediumPhoto == 0) {
          $scope.arrowWieldingPhoto = 2;
        }
        else if($scope.inactiveMediumPhoto == 2) {
          $scope.arrowWieldingPhoto = 1;
        }
        else {
          /*
           In this case, the repeated first item at the end should hold the arrow; this case, though, is handled
           separately in the template
            */
          $scope.arrowWieldingPhoto = null;
        }
      };


      $scope.goToCataloguePage = function () {
        $state.go('catalogue');
      };

      /// Update
      $scope.update = function () {
        $scope.updateStitchesName();
        $scope.updateColorsName();
        $scope.updateGaugesName();
        $scope.updateSecondaryMaterialsName();
      };

      // Update stitches name
      $scope.updateStitchesName = function() {
        $scope.item.stitchesResourceName = [];
        $scope.item.stitches.forEach(function(stitch) {
          if($rootScope.resources.stitches.hashmap[stitch.stitch_id]) {
            $scope.item.stitchesResourceName.push({ "name" : $rootScope.resources.stitches.hashmap[stitch.stitch_id].name});
          }
        });
      };

      // Update colors name
      $scope.updateColorsName = function() {
        $scope.item.colorsResourceName = [];
        $scope.item.colors.forEach(function(color) {
          if($rootScope.resources.colors.hashmap[color.color_id]) {
            $scope.item.colorsResourceName.push({ "name" : $rootScope.resources.colors.hashmap[color.color_id].name});
          }
        });
      };

      // Update gauges name
      $scope.updateGaugesName = function() {
        $scope.item.gaugesResourceName = [];
        $rootScope.resources.gauges.data.forEach(function(elem) {
          var check = false;
          $scope.item.gauges.forEach(function(gauge) {
            if (gauge.gauge_id == elem.id) {
              check = true;
            }
          });
          if(check) {
            $scope.item.gaugesResourceName.push({ "name" : elem.name});
          }
        });
      };

      // Update secondary materials name
      $scope.updateSecondaryMaterialsName = function() {
        $scope.item.secondaryMaterialsResourceName = [];
        $scope.item.secondary_materials.forEach(function(secondary_m) {
          if($rootScope.resources.secondaryMaterials.hashmap[secondary_m.secondary_material_id].name) {
            $scope.item.secondaryMaterialsResourceName.push({ "name" : $rootScope.resources.secondaryMaterials.hashmap[secondary_m.secondary_material_id].name});
          }
        });
      };

    }
  ]);
