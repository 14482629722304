angular
  .module('directives')
  .directive('itemsSelectFilter', itemsSelectFilter)

  itemsSelectFilter.$inject = ['$rootScope'];
  function itemsSelectFilter ($rootScope) {
    var itemsSelectFilterDirective = {
      restrict: 'E',
      scope: {
        filters : '=',  //resource
        filterObj : '=', //filter Obj for api
        filterProp : '@', // filter prop
        //filterModel -> no ngModel in ui-select directive :( - no able to restore filters already setted
        nameField : '@', //Name field if not 'name' property
        title: '@',
        stateCatalogue: '='
      },
      templateUrl: '../../templates/items-select-filter.html',

      link: function (scope, elem, attrs) {
        scope.show = false;
        scope.resourceFilters = []; //filters.data resolved from Api
        scope.model = {selected : []}; //Used by ui-select for binding selected items
        scope.useNameField = ("nameField" in attrs) ? scope.nameField : 'name'; //set item name field

        var setExistingFilters = function(){
          var modelLenght = -1;
            for(var i = 0; i< scope.filterObj[scope.filterProp].length; i++ ){
              for(var j=0; j<scope.resourceFilters.length; j++){
                if(scope.filterObj[scope.filterProp][i] ==  scope.resourceFilters[j].id){
                  //Not .push for obj compare in ui-select directive..
                  modelLenght++;
                  scope.model.selected[modelLenght] = scope.filters.hashmap[scope.filterObj[scope.filterProp][i]];
                }
              }
            }
        };

        scope.clearFilter = function(){
          //Clear directive model
          scope.model = {selected : []};
          //Clear filter obj
          scope.filterObj[scope.filterProp] = [];
        };

        scope.add = function(item){
          if(scope.filterObj[scope.filterProp].indexOf(item.id) === -1){
            scope.filterObj[scope.filterProp].push(item.id);
          }
        };

        scope.remove = function(item){
          scope.filterObj[scope.filterProp].splice(scope.filterObj[scope.filterProp].indexOf(item.id), 1);
        };

        scope.$watch('filters', function(newVal , oldVal){
          if (newVal !== undefined) {
            scope.resourceFilters = scope.filters.data;
            setExistingFilters();
            //For generalize name field, for searching filter
            scope.getResourceFilters = function(searchText){
              var out = [];
              var text = searchText.toLowerCase();
              if(searchText !== '') {
                for(var i = 0; i< scope.resourceFilters.length; i++){
                  if(scope.resourceFilters[i][scope.useNameField].toString().toLowerCase().indexOf(text) !== -1){
                    out.push(scope.resourceFilters[i]);
                  }
                }
              }
              else { out = scope.resourceFilters;}
              return out;
            };
          }
        }, true);
      }
    };

    return itemsSelectFilterDirective;
  }

