angular.module('controllers')
    .controller('HeaderCtrl', [ "$scope", "$state", "$rootScope",  "$auth", "UserService", "ResourcesService", "$translate",
        function($scope, $state, $rootScope, $auth, UserService, ResourcesService, $translate) {
            $scope.loadHeader = true;

            $scope.pagesWithHeaderState = ['items', 'item', 'new-item', 'catalogue', 'catalogue-item', 'mass-upload'];
            $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
                if($scope.pagesWithHeaderState.indexOf(toState.name) === -1){
                    $scope.loadHeader = false;
                } else {
                    $scope.loadHeader = true;
                }
            });

            //Load static resources
            $rootScope.resources = {};

            //Refresh case
            if($rootScope.$storage.profileUser.id){
              ResourcesService.loadResources(
                function(resources) {
                  $rootScope.resources = resources;
                  $rootScope.$broadcast('resource:loaded');
                }
              );
            }

            //Login case
            $rootScope.$on('auth:login-success', function(){
              //Reload for possible change language
              ResourcesService.reloadResources(function(resources){
                $rootScope.resources = resources;
                $rootScope.$broadcast('resource:reloaded');
              })
            });

            //User role function - available in all constroller
            $rootScope.isReadOnlyUser = function(){
              return $rootScope.readOnlyUser = $rootScope.$storage.profileUser.role !== 'admin' && $rootScope.$storage.profileUser.role !== 'office1'
            };

            $rootScope.getUserRole = function(){
              return $rootScope.$storage.profileUser.role;
            };

            $rootScope.isAdmin = function(){
              return $rootScope.$storage.profileUser.role === 'admin';
            };

            $rootScope.isSales = function(){
              return $rootScope.$storage.profileUser.role === 'sales';
            };

            //user initials
            $scope.getUserInitials = function() {
              var initials = "";
              if ($rootScope.$storage.profileUser && $rootScope.$storage.profileUser.first_name && $rootScope.$storage.profileUser.first_name != "") {
                initials = initials + $rootScope.$storage.profileUser.first_name.slice(0,1);
              }

              if ($rootScope.$storage.profileUser && $rootScope.$storage.profileUser.first_name && $rootScope.$storage.profileUser.last_name != "") {
                initials = initials + $rootScope.$storage.profileUser.last_name.slice(0,1);
              }

              return initials;
            };

            //logout
            $scope.handleSignOutBtnClick = function() {
                $auth.signOut()
                    .then(function(resp) {
                        UserService.deleteUserData();
                        $state.go('landing');
                        // handle success response
                    })
                    .catch(function(resp) {
                        // handle error response
                    });
            };

            $scope.changeLanguage = function(lang){

              UserService.changeLocaleUserData(lang,
                function(data){
                  ResourcesService.reloadResources(function(resources){
                    $rootScope.resources = resources;

                    $rootScope.gaugesResource = angular.copy(resources.gauges);
                    $rootScope.$broadcast('resource:reloaded');
                  })
                },
                function(){

                }
              );

              //Close popup
              /*setTimeout(function(){
                angular.element(document.getElementsByClassName('header__user'))[0].click();
              },100);*/
            };


            function detectIE() {
              var ua = window.navigator.userAgent;

              var msie = ua.indexOf('MSIE ');
              var trident = ua.indexOf('Trident/');
              var edge = ua.indexOf('Edge/');

              if (msie > 0 || trident > 0 || edge > 0) {
                // IE 10 or older => return version number
                return true;
              }

              // other browser
              return false;
            }
            $rootScope.isIE = detectIE();

            $scope.isOpen= false;
            $scope.waitToKillPopup = function(){
              $scope.isOpen = !$scope.isOpen;
              var clickFunction = function(){
                document.removeEventListener('click', clickFunction, false);
                if($scope.isOpen){
                  document.getElementsByClassName('header__user-circle')[0].click();
                }
              };

              var timeOutTime = $rootScope.isIE? '200' : '100'; //IE needs more time

              setTimeout(function(){
                if($scope.isOpen) {
                  document.addEventListener("click", clickFunction, false);
                }
              },timeOutTime)
            };
        }
    ]);
