angular.module("services")
  .factory('UserService', ["$rootScope", "$translate", "$http",
    function ($rootScope, $translate, $http) {

      var factory = {};

      if(!$rootScope.$storage.profileUser) {
        $rootScope.$storage.profileUser = {};
      }

      factory.setUserProfile = function (data) {
        //Save user data
        $rootScope.$storage.profileUser = data;

        factory.setUserLanguage(data.locale);
      };

      factory.setUserLanguage = function(locale){
        $rootScope.language = locale;
        $translate.use($rootScope.language);
      };

      factory.deleteUserData = function () {
        //Delete user data
        $rootScope.$storage.profileUser = {};
      };

      factory.changeLocaleUserData = function(locale, succCallback, errCallback){
        var data = {
          user : {
            locale : locale
          }
        };

        $http.put($rootScope.baseUrl +'/users/' + $rootScope.$storage.profileUser.id, data).then(
          function(data, status, headers, config){
            factory.setUserProfile(data.data);
            succCallback(data);
          },
          function(data, status, headers, config){
            console.log(data);
            errCallback(data);
          }
        );
      };

      return factory;
    }
  ]);
