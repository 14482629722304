angular.module('directives')
  .directive( 'multipleRow', [ "$rootScope" , function($rootScope) {
    var multipleRowDirective = {
      restrict: 'E',
      transclude: true,
      scope: {
        resourceName: '@', //Field name in resources
        itemResourceName : '@', //item field name
        itemResource:'=', //item field to bind with changes
        emptyPossibile: '@', //empty option is possible
        fSelectPlaceholder: '@',
        fOptions: '=', // first select options data
        fModel: '@',   // first select property
        fSelectFiltered: '@', // normal==false , filtered select==true
        sSelectFiltered: '@', // normal==false , filtered select==true
        sSelectPlaceholder: '@',
        sOptions: '=', //second select options data
        sModel: '@', //second select property
        title: '@'
      },
      templateUrl: '../../templates/multiple-row.html',

      link: function (scope, elem, attrs) {

        scope.resources = $rootScope.resources;
        scope.$watch(function(){return $rootScope.resources;}, function(){
          scope.resources = $rootScope.resources;
        }, true);

        scope.invalid = [];
        var setInvalid = function(index){
          scope.invalid.push(index);
        };
        var setValid = function(index){
          var indexV = scope.invalid.indexOf(index);
          if(indexV !== -1){
            scope.invalid.splice(indexV,1);
          }
        };
        scope.isInvalid = function(index){
          return scope.invalid.indexOf(index) !== -1;
        };

        var lastRowIsEmpty = function(){
            var lastItem = scope.itemResource[scope.itemResource.length-1];
            if(scope.itemResource.length > 0 && lastItem[scope.fModel] === '' && lastItem[scope.sModel] === ''){
              return true;
            } else return false;
        };

        scope.addRow = function(){
          //Add new row if last isn't empty
          if(!lastRowIsEmpty()){
            var newItem = {};
            newItem[scope.fModel] = '';
            newItem[scope.sModel] = '';

            scope.itemResource.push(newItem);
          }
        };

        scope.deleteRow = function(index){
          if(scope.itemResource.length == 1) {
            scope.itemResource[index][scope.fModel] = '';
            scope.itemResource[index][scope.sModel] = '';
          } else{
            scope.itemResource.splice(index, 1);
          }

          //items index has changed!
          scope.invalid = [];
          scope.validateCouple();
        };

        scope.validateCouple = function(){
          if(scope.itemResource.length === 1) {scope.invalid = [];}
          for(var index=0; index< scope.itemResource.length; index++) {
            var stop = false;
            for (var i = 0; i < scope.itemResource.length && !stop; i++) {
              if (index !== i && scope.itemResource[index][scope.fModel] !== '' &&
                (scope.itemResource[index][scope.fModel] == scope.itemResource[i][scope.fModel]) &&
                (scope.itemResource[index][scope.sModel] == scope.itemResource[i][scope.sModel])) {
                setInvalid(index);
                stop = true;
              }
            }
            if(!stop) setValid(index);
          }
        };

        var deleteEmptyCouples = $rootScope.$on('deleteInvalidCouples',function(){
          for (var i = 0; i < scope.itemResource.length; i++) {
            if (scope.itemResource[i][scope.fModel] === '' && scope.itemResource[i][scope.sModel] === '') {
              scope.itemResource.splice(i, 1);
            }
          }
        });
        $rootScope.$on('$destroy', deleteEmptyCouples);
      }
    };

    return multipleRowDirective;
  }]);
