angular.module("services")
  .factory('ResourcesService', ['$rootScope', '$http', '$filter',
    function ($rootScope, $http, $filter) {
      var factory = {};
      var resources = {};

      var initResource = function(){
        resources = {
          adornments: {data: [], hashmap: {}},
          adornment_categories: {data: [], hashmap: {}},
          bottomItemLenghts: {data: [], hashmap: {}},
          closures: {data: [], hashmap: {}},
          closurePositions: {data: [], hashmap: {}},
          collections: {data: [], hashmap: {}},
          clothingBrands: {data: [], hashmap: {}},
          customers: {data: [], hashmap: {}},
          seasons: {data: [], hashmap: {}},
          colors: {data: [], hashmap: {}},
          decorationPatterns: {data: [], hashmap: {}},
          decorationPatternCategories: {data: [], hashmap: {}},
          decorationPositions: {data: [], hashmap: {}},
          fittings: {data: [], hashmap: {}},
          finishings: {data: [], hashmap: {}},
          finishingCategories: {data: [], hashmap: {}},
          garmentAccessories: {data: [], hashmap: {}},
          garmentAccessoryCategories: {data: [], hashmap: {}},
          garmentAccessoryPositions: {data: [], hashmap: {}},
          gauges: {data: [], hashmap: {}},
          genders: {data: [], hashmap: {}},
          lengths: {data: [], hashmap: {}},
          necklines: {data: [], hashmap: {}},
          necklineCategories: {data: [], hashmap: {}},
          necklinePositions: {data: [], hashmap: {}},
          pockets: {data: [], hashmap: {}},
          pocketsPositions: {data: [], hashmap: {}},
          secondaryMaterials: {data: [], hashmap: {}},
          secondaryMaterialCategories: {data: [], hashmap: {}},
          sleeves: {data: [], hashmap: {}},
          sleeveLengths: {data: [], hashmap: {}},
          sleeveWidths: {data: [], hashmap: {}},
          stitches: {data: [], hashmap: {}},
          stitchCategories: {data: [], hashmap: {}},
          textileFibres: {data: [], hashmap: {}},
          topItemLengths: {data: [], hashmap: {}},
          looks: {data: [], hashmap: {}},
          lookCategories: {data: [], hashmap: {}},
          yarns: {data: [], hashmap: {}},
          years: {data : [], hashmap : {}}
        };
      };
      initResource();

      factory.reloadResources = function(callback){
        initResource();
        factory.loadResources(function(resources){
          callback(resources);
        });
      };

      var checkIfYearExist = function(data, year) {
        for(var i= 0; i < data.length; i++) {
          if (data[i].id == year)
            return false;
        }
        return true;
      };

      factory.loadResources = function(callback) {
        if (resources.adornments.data.length) {
          callback(resources);
        } else {
          $http.get($rootScope.baseUrl + '/resources').
            success(function(data) {

              resources.adornments.data = data.adornments;
              for(var i=0; i< resources.adornments.data.length; i++){
                resources.adornments.hashmap[resources.adornments.data[i].id] = resources.adornments.data[i]; // id : obj
              }

              resources.adornment_categories.data = data.adornment_categories;
                for(var i=0; i< resources.adornment_categories.data.length; i++){
                  resources.adornment_categories.hashmap[resources.adornment_categories.data[i].id] = resources.adornment_categories.data[i]; // id : obj
              }

/*
              resources.bottomItemLenghts.data = data.bottom_item_lengths;
              for(var i=0; i< resources.bottomItemLenghts.data.length; i++){
                resources.bottomItemLenghts.hashmap[resources.bottomItemLenghts.data[i].id] = resources.bottomItemLenghts.data[i]; // id : obj
              }
*/
              resources.closures.data = data.closures;
              for(var i=0; i< resources.closures.data.length; i++){
                resources.closures.hashmap[resources.closures.data[i].id] = resources.closures.data[i]; // id : obj
              }

              resources.closurePositions.data = data.closure_positions;
              for(var i=0; i< resources.closurePositions.data.length; i++){
                resources.closurePositions.hashmap[resources.closurePositions.data[i].id] = resources.closurePositions.data[i]; // id : obj
              }


              resources.clothingBrands.data = data.clothing_brands;
              for(var i=0; i< resources.clothingBrands.data.length; i++){
                resources.clothingBrands.hashmap[resources.clothingBrands.data[i].id] = resources.clothingBrands.data[i]; // id : obj
              }

              resources.customers.data = data.customers;
              for(var i=0; i< resources.customers.data.length; i++){
                resources.customers.hashmap[resources.customers.data[i].id] = resources.customers.data[i]; // id : obj
              }

              resources.seasons.data = data.seasons;
              for(var i=0; i< resources.seasons.data.length; i++){
                resources.seasons.hashmap[resources.seasons.data[i].id] = resources.seasons.data[i]; // id : obj
              }

              resources.colors.data = data.colors;
              for(var i=0; i< resources.colors.data.length; i++){
                resources.colors.hashmap[resources.colors.data[i].id] = resources.colors.data[i]; // id : obj
              }

              resources.decorationPatterns.data = data.decoration_patterns;
              for(var i=0; i< resources.decorationPatterns.data.length; i++){
                resources.decorationPatterns.hashmap[resources.decorationPatterns.data[i].id] = resources.decorationPatterns.data[i]; // id : obj
              }

              resources.decorationPatternCategories.data = data.decoration_pattern_categories;
              for(var i=0; i< resources.decorationPatternCategories.data.length; i++){
                resources.decorationPatternCategories.hashmap[resources.decorationPatternCategories.data[i].id] = resources.decorationPatternCategories.data[i]; // id : obj
              }

              resources.decorationPositions.data = data.decoration_positions;
              for(var i=0; i< resources.decorationPositions.data.length; i++){
                resources.decorationPositions.hashmap[resources.decorationPositions.data[i].id] = resources.decorationPositions.data[i]; // id : obj
              }

              resources.fittings.data = data.fittings;
              for(var i=0; i< resources.fittings.data.length; i++){
                resources.fittings.hashmap[resources.fittings.data[i].id] = resources.fittings.data[i]; // id : obj
              }

              resources.finishings.data = data.finishings;
              for(var i=0; i< resources.finishings.data.length; i++){
                resources.finishings.hashmap[resources.finishings.data[i].id] = resources.finishings.data[i]; // id : obj
              }

              resources.finishingCategories.data = data.finishing_categories;
              for(var i=0; i< resources.finishingCategories.data.length; i++){
                resources.finishingCategories.hashmap[resources.finishingCategories.data[i].id] = resources.finishingCategories.data[i]; // id : obj
              }

              resources.garmentAccessories.data = data.garment_accessories;
              for(var i=0; i< resources.garmentAccessories.data.length; i++){
                resources.garmentAccessories.hashmap[resources.garmentAccessories.data[i].id] = resources.garmentAccessories.data[i]; // id : obj
              }

              resources.garmentAccessoryCategories.data = data.garment_accessory_categories;
              for(var i=0; i< resources.garmentAccessoryCategories.data.length; i++){
                resources.garmentAccessoryCategories.hashmap[resources.garmentAccessoryCategories.data[i].id] = resources.garmentAccessoryCategories.data[i]; // id : obj
              }

              resources.garmentAccessoryPositions.data = data.garment_accessory_positions;
              for(var i=0; i< resources.garmentAccessoryPositions.data.length; i++){
                resources.garmentAccessoryPositions.hashmap[resources.garmentAccessoryPositions.data[i].id] = resources.garmentAccessoryPositions.data[i]; // id : obj
              }

              resources.gauges.data = data.gauges;
              for(var i=0; i< resources.gauges.data.length; i++){
                resources.gauges.hashmap[resources.gauges.data[i].id] = resources.gauges.data[i]; // id : obj

                //Transform to integer for order
                resources.gauges.data[i].number =  isNaN(parseInt(resources.gauges.data[i].name)) ? 0 : parseInt(resources.gauges.data[i].name);
              }

              //Order gauges by number
              resources.gauges.data = $filter('orderBy')(resources.gauges.data, "number");

              resources.genders.data = data.genders;
              for(var i=0; i< resources.genders.data.length; i++){
                resources.genders.hashmap[resources.genders.data[i].id] = resources.genders.data[i]; // id : obj
              }

              resources.necklines.data = data.necklines;
              for(var i=0; i< resources.necklines.data.length; i++){
                resources.necklines.hashmap[resources.necklines.data[i].id] = resources.necklines.data[i]; // id : obj
              }

              resources.necklineCategories.data = data.neckline_categories;
              for(var i=0; i< resources.necklineCategories.data.length; i++){
                resources.necklineCategories.hashmap[resources.necklineCategories.data[i].id] = resources.necklineCategories.data[i]; // id : obj
              }

              resources.necklinePositions.data = data.neckline_positions;
              for(var i=0; i< resources.necklinePositions.data.length; i++){
                resources.necklinePositions.hashmap[resources.necklinePositions.data[i].id] = resources.necklinePositions.data[i]; // id : obj
              }

              resources.pockets.data = data.pockets;
              for(var i=0; i< resources.pockets.data.length; i++){
                resources.pockets.hashmap[resources.pockets.data[i].id] = resources.pockets.data[i]; // id : obj
              }

              resources.pocketsPositions.data = data.pocket_positions;
              for(var i=0; i< resources.pocketsPositions.data.length; i++){
                resources.pocketsPositions.hashmap[resources.pocketsPositions.data[i].id] = resources.pocketsPositions.data[i]; // id : obj
              }

              resources.secondaryMaterials.data = data.secondary_materials;
              for(var i=0; i< resources.secondaryMaterials.data.length; i++){
                resources.secondaryMaterials.hashmap[resources.secondaryMaterials.data[i].id] = resources.secondaryMaterials.data[i]; // id : obj
              }

              resources.secondaryMaterialCategories.data = data.secondary_material_categories;
              for(var i=0; i< resources.secondaryMaterialCategories.data.length; i++){
                resources.secondaryMaterialCategories.hashmap[resources.secondaryMaterialCategories.data[i].id] = resources.secondaryMaterialCategories.data[i]; // id : obj
              }

              resources.sleeves.data = data.sleeves;
              for(var i=0; i< resources.sleeves.data.length; i++){
                resources.sleeves.hashmap[resources.sleeves.data[i].id] = resources.sleeves.data[i]; // id : obj
              }

              resources.sleeveLengths.data = data.sleeve_lengths;
              for(var i=0; i< resources.sleeveLengths.data.length; i++){
                resources.sleeveLengths.hashmap[resources.sleeveLengths.data[i].id] = resources.sleeveLengths.data[i]; // id : obj
              }

              resources.sleeveWidths.data = data.sleeve_widths;
              for(var i=0; i< resources.sleeveWidths.data.length; i++){
                resources.sleeveWidths.hashmap[resources.sleeveWidths.data[i].id] = resources.sleeveWidths.data[i]; // id : obj
              }

              resources.stitches.data = data.stitches;
              for(var i=0; i< resources.stitches.data.length; i++){
                resources.stitches.hashmap[resources.stitches.data[i].id] = resources.stitches.data[i]; // id : obj
              }

              resources.stitchCategories.data = data.stitch_categories;
              for(var i=0; i< resources.stitchCategories.data.length; i++){
                resources.stitchCategories.hashmap[resources.stitchCategories.data[i].id] = resources.stitchCategories.data[i]; // id : obj
              }

              resources.textileFibres.data = data.textile_fibres;
              for(var i=0; i< resources.textileFibres.data.length; i++){
                resources.textileFibres.hashmap[resources.textileFibres.data[i].id] = resources.textileFibres.data[i]; // id : obj
              }

              resources.lengths.data = data.lengths;
              for(var i=0; i< resources.lengths.data.length; i++){
                resources.lengths.hashmap[resources.lengths.data[i].id] = resources.lengths.data[i]; // id : obj
              }

              resources.looks.data = data.looks;
              for(var i=0; i< resources.looks.data.length; i++){
                resources.looks.hashmap[resources.looks.data[i].id] = resources.looks.data[i]; // id : obj
              }

              resources.lookCategories.data = data.look_categories;
              for(var i=0; i< resources.lookCategories.data.length; i++){
                resources.lookCategories.hashmap[resources.lookCategories.data[i].id] = resources.lookCategories.data[i]; // id : obj
              }

              resources.yarns.data = data.yarns;
              for(var i=0; i< resources.yarns.data.length; i++){
                resources.yarns.hashmap[resources.yarns.data[i].id] = resources.yarns.data[i]; // id : obj
              }

              resources.collections.data = data.collections;
              for(var i=0; i< resources.collections.data.length; i++){
                resources.collections.hashmap[resources.collections.data[i].id] = resources.collections.data[i]; // id : obj

                if(resources.collections.data[i].clothing_brand_id == null) {
                  var string = resources.seasons.hashmap[resources.collections.data[i].season_id].name
                    + ' - '
                    + resources.collections.data[i].year;
                } else {
                  var string =  resources.customers.hashmap[resources.clothingBrands.hashmap[resources.collections.data[i].clothing_brand_id].customer_id].company
                    + ' - '
                    + resources.clothingBrands.hashmap[resources.collections.data[i].clothing_brand_id].name
                    + ' - '
                    + resources.seasons.hashmap[resources.collections.data[i].season_id].name
                    + ' - '
                    + resources.collections.data[i].year;

                }

                resources.collections.data[i].selectString = string;
              }

              //Build years resource from collection
              for(var i=0; i< resources.collections.data.length; i++){
                //If year not present yet

                if (checkIfYearExist(resources.years.data, resources.collections.data[i].year)) {
                  var yearString = resources.collections.data[i].year + '';
                  resources.years.data.push({
                    id: yearString,
                    name: yearString
                  });
                  var dataLength = resources.years.data.length == 0 ? 0 : resources.years.data.length - 1;
                  //Has to be the same obj
                  resources.years.hashmap[resources.collections.data[i].year] = resources.years.data[dataLength];
                }
              }
              callback(resources);
            }).
            error(function(error) {
              console.log(error);
            });
        }
      };

      factory.getResourceByIds = function(resource, resourcesId, callback) {

        factory.loadResources(
          function (resources) {
            var searchedResource = [];
            angular.forEach(resourcesId, function (resourcesId) {
              searchedResource.push(resources[resource].hashmap[resourcesId]);
            });
            callback(searchedResource);
          }

        );
      };

      return factory;
    }

  ]);
