angular.module('controllers')
  .controller('NewItemCtrl', ["$rootScope", "$scope", "$window", "$stateParams", "$auth", "$state", '$filter', "UserService", "ItemsService", "Restangular", "Upload",
    function($rootScope, $scope, $window, $stateParams, $auth, $state, $filter, UserService, ItemsService, Restangular, Upload) {

      $scope.saving = false;

      $scope.item = {
        "colors": [],
        "gauges": [],
        //{
        //  "gauge_id": ''
        //}],
        "secondary_materials": [],
        "stitches": [
          {
            "stitch_id": ''
          }],
        "yarns": [
          {
            "yarn_id": ''
          }],
        "adornments": [
          {
            //"id": '',
            "adornment_id": '',
            "decoration_position_id": ''
          }],
        "closures": [
          {
            //"id": '',
            "closure_id": '',
            "closure_position_id": ''
          }
        ],
        "decoration_patterns": [
          {
            //"id": '',
            "decoration_pattern_id": '',
            "decoration_position_id": ''
          }
        ],
        "finishings": [
          {
            //"id": '',
            "finishing_id": '',
            "decoration_position_id": ''
          }
        ],
        "garment_accessories": [
          {
            //"id": '',
            "garment_accessory_id": '',
            "garment_accessory_position_id": ''
          }
        ],
        "pockets": [
          {
            //"id": '',
            "pocket_id": '',
            "pocket_position_id": ''
          }
        ],
        "textile_fibres": [
          {
            //"id": '',
            "textile_fibre_id": '',
            "percentage": ''
          }
        ],
        "is_prototype": false,
        "look_id": '',
        "gender_id": '',
        "collection_id": '',
        "fitting_id": '',
        "length_id": '',
        "sleeve_id": '',
        "neckline_id": '',
        "neckline_position_id": '',
        "sleeve_length_id": '',
        "sleeve_width_id": '',
        "model_code": "",
        "article_code": "",
        "catalogue": false
      };

      $scope.itemCategoryLook = {name : '' , id: ''};

      $scope.necklinePosEnabled = false;

      var formErrorsInit = function(){
        $scope.formErrors = {
          gender : true,
          model_code : true,
          server_model_code : true,
          article_code : true,
          collection_id : true,
          look_categories : true,
          look : true,
          percentage : true,
          gauges : true,
          repeatedCouples : true
        };
      };
      formErrorsInit();

      var init = function(){
        if($rootScope.resources){
          $scope.gaugesResource = angular.copy($rootScope.resources.gauges);
        }
      };
      init();

      //Return true if all mandatory fields are setted
      //Delete empty fields
      $scope.isValid = true;
      var isValidData = function(){
        formErrorsInit();

        //Required TODO (mancano scollo, manica con boolean)
        $rootScope.$emit('deleteInvalidCouples');

        //Delete empty entries
        var deleteEmpty = ['gauges', 'stitches', 'yarns'];
        for(var prop = 0; prop < deleteEmpty.length; prop++){
          var property = deleteEmpty[prop];
          var property_id = property == 'stitches'? 'stitch_id' : property.slice(0, property.length-1) + '_id';
          for(var i=0; i< $scope.item[property].length; i++){
            if($scope.item[property][i][property_id] == ''){
              $scope.item[property].splice(i,1);
            }
          }
        }
        //Delete empty textile_fibres rows
        for(var f=$scope.item.textile_fibres.length-1; f>= 0; f--){
          if($scope.item.textile_fibres[f].textile_fibre_id == '' && $scope.item.textile_fibres[f].percentage == ''){
            $scope.item.textile_fibres.splice(f,1);
          }
        }

        var errors = {
          gender : $scope.item.gender_id !== '',
          model_code : $scope.item.model_code !== '',
          server_model_code : true,
          article_code : $scope.item.article_code !== '',
          collection_id : $scope.item.collection_id !== '',
          look_categories : $scope.itemCategoryLook.id !== '' && $scope.itemCategoryLook.id !== undefined,
          look : ($scope.item.look_id !== '' && $scope.item.look_id !== undefined),
          percentage : $scope.isTextilePercentageOk(),
          gauges : $scope.item.gauges.length !== 0,
          repeatedCouples : $window.document.getElementsByClassName('item-form__error').length === 0
        };

        $scope.formErrors = errors;

        $scope.isValid = true;
        for(error in errors){
          $scope.isValid = $scope.isValid && errors[error];
        }
        //console.log('Item valido?: ' + $scope.isValid);
        return $scope.isValid;
      };

      $scope.saveItem = function(){
        if(isValidData()){
          $scope.saving = true;
          //remove angular $$hashkey
          var cleanItem = angular.copy($scope.item);

          if ($scope.sketch_image){
            delete cleanItem['sketch_image_destroy'];
            cleanItem.sketch_image = $scope.sketch_image[0];
          }
          if ($scope.front_image){
            delete cleanItem['front_image_destroy'];
            cleanItem.front_image = $scope.front_image[0];
          }
          if ($scope.back_image){
            delete cleanItem['back_image_destroy'];
            cleanItem.back_image = $scope.back_image[0];
          }
          if ($scope.details_image){
            delete cleanItem['details_image_destroy'];
            cleanItem.details_image = $scope.details_image[0];
          }

          var formData = Object.toFormData(cleanItem);

          ItemsService.postItem(formData).then(
            function (data) {
              $scope.saving = false;
              $state.go('item', {item: data.id});
            },
            function(errors){
              $scope.saving = false;
              if(errors.data) $scope.isValid = false;

              for(var k in errors.data){
                $scope.formErrors['server_' + k] = false;
              }

              ItemsService.createEmptyCouplesinItem($scope.item);
            }
          );

        }else{ItemsService.createEmptyCouplesinItem($scope.item);}
      };

      $scope.deleteImg = function(which){
        $scope[which + '_image'] = undefined;
        $scope.item[which + '_image_thumb'] = '';
        $scope.item[which + '_image_medium'] = '';
        $scope.item[which + '_image_original_file_name'] = null;
        $scope.item[which + '_image_destroy'] = 'true';
      };

      //Handle gauges checkbox
      $scope.handleGauges = function(index, value){
        if(value){
          $scope.item.gauges.push({ gauge_id : $scope.gaugesResource.data[index].id});
        }
        else{
          var stop = false;
          for(var i=0; i<$scope.item.gauges.length && !stop; i++){
            if($scope.item.gauges[i].gauge_id == $scope.gaugesResource.data[index].id){
              $scope.item.gauges.splice(i,1);
              stop = true;
            }
          }
        }
      };

      //Textile Fibres
      $scope.isTextilePercentageOk = function(){
        var sum = 0;
        for(var i=0; i< $scope.item.textile_fibres.length; i++){
          if($scope.item.textile_fibres[i].percentage !== ''){
            sum += parseFloat($scope.item.textile_fibres[i].percentage);
          }
        }
        return sum<=100;
      };

      $scope.deleteTextileRow = function(index){
        if($scope.item.textile_fibres.length == 1) {
          $scope.item.textile_fibres[index]['textile_fibre_id'] = '';
          $scope.item.textile_fibres[index]['percentage'] = '';
        } else{
          $scope.item.textile_fibres.splice(index, 1);
        }
      };

      $scope.addTextileRow = function(){
        if($scope.isTextilePercentageOk()){
          var newItem = {
            textile_fibre_id : '',
            percentage: ''
          };
          $scope.item.textile_fibres.push(newItem);
        }
      };

      //Stitches
      $scope.deleteStitchesRow = function(index){
        if($scope.item.stitches.length == 1) {
          $scope.item.stitches[index]['stitch_id'] = '';
        } else{
          $scope.item.stitches.splice(index, 1);
        }
      };

      $scope.addStitchesRow = function(){
        if($scope.item.stitches.length > 0 && $scope.item.stitches[$scope.item.stitches.length-1].stitch_id !== '') {
          var newItem = {
            stitch_id: ''
          };
          $scope.item.stitches.push(newItem);
        }
      };

      //Yarns
      $scope.deleteYarnsRow = function(index){
        if($scope.item.yarns.length == 1) {
          $scope.item.yarns[index]['yarn_id'] = '';
        } else{
          $scope.item.yarns.splice(index, 1);
        }
      };

      $scope.addYarnsRow = function(){
        if($scope.item.yarns.length > 0 && $scope.item.yarns[$scope.item.yarns.length-1].yarn_id !== '') {
          var newItem = {
            yarn_id: ''
          };
          $scope.item.yarns.push(newItem);
        }
      };

      //Colors
      $scope.loadColorsTags = function(query) {
        return $filter('filter')($rootScope.resources.colors.data, query);
      };

      var tagIndexInColors = function(colorId){
        for(var i=0; i< $scope.item.colors.length ; i++){
          if($scope.item.colors[i].color_id == colorId){
            return i;
          }
        }
      };

      $scope.addColorsTag = function(tag){
        $scope.item.colors.push({color_id : tag.id});
      };

      $scope.removeColorsTag = function(tag){
        var index = tagIndexInColors(tag.id);
        $scope.item.colors.splice(index,1);

      };

      //Secondary materials
      $scope.loadMaterialsTags = function(query) {
        return $filter('filter')($rootScope.resources.secondaryMaterials.data, query);
      };

      var tagIndexInMaterials = function(materialId){
        for(var i=0; i< $scope.item.secondary_materials.length ; i++){
          if($scope.item.secondary_materials[i].secondary_material_id == materialId){
            return i;
          }
        }
      };

      $scope.addMaterialsTag = function(tag){
        $scope.item.secondary_materials.push({secondary_material_id : tag.id});
      };

      $scope.removeMaterialsTag = function(tag){
        var index = tagIndexInMaterials(tag.id);
        $scope.item.secondary_materials.splice(index,1);
      };

      $scope.onNecklineSelection = function(neckline) {
        eraseNecklinePosition();
        if(neckline) {
          toggleNecklinePos(true);
        }
        else {
          toggleNecklinePos(false);
        }
      };

      function eraseNecklinePosition() {
        $scope.item.neckline_position_id = null;
      };

      function toggleNecklinePos(enabled) {
        $scope.necklinePosEnabled = enabled;
      };

      //Check if $index item already exist for set error class - used by 'textile_fibres', 'stitches', 'yarns',
      $scope.isInvalid = function(model, $index, property){
        if(model[$index][property] == '') return false;
        for(var i=0; i< model.length; i++){
          if(model[i][property] == model[$index][property] && i !== $index){
            return true;
          }
        }
        return false;
      };

      //Events
      //var resourceReloaded = $rootScope.$on('resource:reloaded', resourceReloaded());
      var resourceLoaded = $rootScope.$on('resource:loaded', function() {init()});

      var unbind = [/*resourceReloaded,*/ resourceLoaded];
      $scope.$on('$destroy', function () {
        unbind.forEach(function (fn) {
          fn();
        });
      });
    }
  ]);
