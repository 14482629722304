angular.module("services")
  .factory('MassUploadService', ["$rootScope", "$http",
    function ($rootScope, $http) {

      var factory = {};

      factory.getErrors = function() {
        return $http.get($rootScope.baseUrl + '/upload_errors');
      };

      factory.postError = function(error) {
        return $http.post($rootScope.baseUrl + '/upload_errors', {
          upload_error: error
        });
      };

      factory.putError = function(id, error) {
        return $http.put($rootScope.baseUrl + '/upload_errors/' + id, {
          upload_error: error
        })
      };

      factory.deleteError = function(id) {
        return $http.delete($rootScope.baseUrl + '/upload_errors/' + id);
      };

      return factory;
    }
  ]);
