angular
  .module('directives')
  .directive('itemsCheckboxFilter', itemsCheckboxFilter)
  .filter('toArray', toArray);

itemsCheckboxFilter.$inject = ['$rootScope'];
function itemsCheckboxFilter ($rootScope) {
  var itemsCheckboxFilterDirective = {
    restrict: 'E',
    scope: {
      filters : '=',  //resource
      filterModel : '=', // filterObj.prop
      title: '@',
      stateCatalogue: '='
    },
    templateUrl: '../../templates/items-checkbox-filter.html',

    link: function (scope, elem, attrs) {
      scope.show = false;
      scope.resourceFilters = {}; //resource hashmap

      var setCheckboxes = function(){
        //Set checked
        for (var i=0; i< scope.filterModel.length; i++){
          scope.resourceFilters[scope.filterModel[i]].selected = true;
        }
      };

      scope.clearFilter = function(){
        //Clear checked
        for(var i=0; i< scope.filterModel.length; i++){
          scope.resourceFilters[scope.filterModel[i]].selected = false;
        }
        //Clear filter obj
        scope.filterModel = [];
      };

      scope.setInOut = function(id){
        var indexOf = scope.filterModel.indexOf(id);
        if(indexOf === -1){
          scope.filterModel.push(id);
        }else{
          scope.filterModel.splice(indexOf,1);
        }
      };

      scope.$watch('filters', function(newVal , oldVal){
        if (newVal !== undefined) {
          //Copy obj - no dirty on original
          scope.resourceFilters = angular.copy(scope.filters.hashmap);
          setCheckboxes();
        }
      }, true);
    }
  };

  return itemsCheckboxFilterDirective;
};

function toArray(){
  return function(obj) {
    var result = [];
    angular.forEach(obj, function(val, key) {
      result.push(val);
    });
    return result;
  };
};
