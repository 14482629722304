angular.module('controllers')
    .controller('ForgotPasswordCtrl', ["$rootScope", "$scope", "$auth", "UserService",
        function($rootScope, $scope, $auth, UserService) {
            $scope.handlePwdResetBtnClick = function() {
                $auth.requestPasswordReset($scope.pwdResetForm)
                    .then(function(resp) {
                        // handle success response
                    })
                    .catch(function(resp) {
                        // handle error response
                    });
            };
        }
    ]);