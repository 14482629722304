angular.module('directives')
  .directive('zoomable', ['$rootScope', zoomable]);

function zoomable($rootScope) {
  var zoomableDirective = {
    restrict: 'A',
    scope: {
      largeImage: "=",
      size: "@",
      aClass: "@"
    },
      link: linkFunction
  };

  function linkFunction(scope, elem, attrs) {
    var initZoom = function() {
      scope.size = scope.size || '90%';
      if(scope.aClass) {
        scope.aClass = ' class="' + scope.aClass + '"';
      }
      else {
        scope.aClass = '';
      }

      // Take the original image and insert it into a dedicated DOM structure
      var newDiv = $('<div class="zoomable-img"><a' + scope.aClass + ' href="' + scope.largeImage + '">' + $(elem).prop('outerHTML') + '</a></div>').replaceAll(elem);

      // Initialise the plugin on the 'a' tag of the new DOM structure
      $(newDiv).find('a').swinxyzoom({mode: 'window', controls: false, size: scope.size, zoom: 12});

      /*
       For mobile devices, this implementation gives the zoom plugin a different behaviour.
       More specifically, we want the plugin to zoom out upon stopping touching the image, which wouldn't normally happen.
       */

      scope.paused = false;
      scope.fading = false;
      scope.iFired = false;

      /*
       Once a touchend event is fired, check if it was fired by the plugin's DOM components and, in that case,
       hide the zoom overlay as if a mouseleave event had been fired.
       */
      $(newDiv).on("touchend", function() {

        var overlay = $(newDiv).find('.sxy-zoom-viewport'),
          viewfinder = $(newDiv).find('.sxy-zoom-viewfinder');

        // Hide the zoom overlay components with a similar effect to the original
        if(overlay) {
          $(overlay[0]).fadeTo(200, '0');
        }
        if(viewfinder) {
          $(viewfinder[0]).hide()
        }

        // Remember that the plugin has been "paused".
        scope.paused = true;
      });

      /*
       Once a touchstart event is fired, check whether the chosen image was "paused", and restore it if that's
       the case.
       */
      $(newDiv).on("touchstart", function() {

        // I should not unpause myself, so I remember I fired the event
        scope.iFired = true;

        // Let the other zoom directives know that they should unpause
        $rootScope.$emit('zoomErasePauses');

        /*
         If the zoom plugin is paused, but not fading, restore it; otherwise, don't do anything, the plugin will
         do its own stuff
         */
        if(scope.paused && !scope.fading) {
          // Unpause the plugin
          scope.paused = false;

          var overlay = $(newDiv).find('.sxy-zoom-viewport'),
            viewfinder = $(newDiv).find('.sxy-zoom-viewfinder');

          // While the fading is happening, we don't want the user to keep poking at our plugin, do we?
          scope.fading = true;
          if(overlay) {
            $(overlay[0]).fadeTo(200, '1', function() {
              // Now he can, calm down will ya
              scope.fading = false;
            });
          }
          if(viewfinder) {
            $(viewfinder[0]).show()
          }
        }
      });
    };

    // When another zoomable image is touched, we need to unpause this one, to prevent it from glitching.
    $rootScope.$on('zoomErasePauses', function() {
      if(scope.iFired) {
        scope.iFired = false;
      }
      else {
        scope.paused = false;
      }
    });

    // Wait for the image to load before initialising the plugin
    elem.on('load', function() {
      initZoom();
    });
  }

  return zoomableDirective;
}
