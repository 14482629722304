angular.module('controllers')
  .controller('ItemCtrl', ["$rootScope", "$scope", "$localStorage", "$window", "$stateParams", "$auth", "$state", '$filter', "UserService", "MassUploadService", "ItemsService", "Restangular", "ModalFactory", "FoundationApi",
    function($rootScope, $scope, $localStorage, $window, $stateParams, $auth, $state, $filter, UserService, MassUploadService, ItemsService, Restangular, ModalFactory, FoundationApi) {

      var itemId =  $stateParams.item;
      $rootScope.itemIdentification = $stateParams.item;
      //$scope.resources = {};
      $scope.item = {};
      $scope.itemCategoryLook = {};
      $scope.savedData = {};

      $scope.showButtons = false;

      //Show item edit
      $scope.editItem = false;

      //Show spinner
      $scope.saving = false;

      var formErrorsInit = function(){
        $scope.formErrors = {
          gender : true,
          model_code : true,
          server_model_code : true,
          article_code : true,
          collection_id : true,
          look_categories : true,
          look : true,
          percentage : true,
          gauges : true,
          repeatedCouples : true
        };
      };
      formErrorsInit();

      var init = function(){
        $scope.gaugesResource = angular.copy($rootScope.resources.gauges);

        if($scope.item.look_id){
          $scope.itemCategoryLook = angular.copy($rootScope.resources.lookCategories.hashmap[$rootScope.resources.looks.hashmap[$scope.item.look_id].look_category_id]);
        }else{
          $scope.itemCategoryLook = {id : '', name : ''};
        }
        $scope.savedData.itemCategoryLook = angular.copy($scope.itemCategoryLook);
        //Resources are loaded, show modify button
        $scope.showButtons = true;
      };

      //Set gauges selected checkbox
      var setGauges = function(){
        for(var i=0; i<$scope.gaugesResource.data.length; i++){
          for(var j=0; j<$scope.item.gauges.length; j++){
            if($scope.gaugesResource.data[i].id == $scope.item.gauges[j].gauge_id){
              $scope.gaugesResource.data[i].selected = true;
            }
          }
        }
      };

      $scope.colorsTags = [];
      var setColors = function(){
        for(var i=0; i< $scope.item.colors.length; i++) {
          $scope.colorsTags.push($rootScope.resources.colors.hashmap[$scope.item.colors[i].color_id]);
        }
      };

      $scope.materialsTags = [];
      var setMaterials = function(){
        for(var i=0; i< $scope.item.secondary_materials.length; i++) {
          $scope.materialsTags.push($rootScope.resources.secondaryMaterials.hashmap[$scope.item.secondary_materials[i].secondary_material_id]);
        }
      };


      //Get item
      $scope.getItem = function () {
        ItemsService.getItem(itemId).then(function (item) {

          ItemsService.createEmptyCouplesinItem(item);
          $scope.item = Restangular.copy(item);

          $scope.update($scope.item);

          $scope.savedData.item = Restangular.copy(item);

          $scope.necklinePosEnabled = !!item.neckline_id;

          if (!(angular.equals({}, $rootScope.resources))) {
            $scope.update($scope.item);
            init();
          }
        });
      };

      if(!(angular.equals({}, $rootScope.resources))){
        $scope.getItem();
      }

      var resourceLoaded = $rootScope.$on('resource:loaded', function() {
        $scope.getItem();
      });

      var unbind = [resourceLoaded];
      $scope.$on('$destroy', function () {
        unbind.forEach(function (fn) {
          fn();
        });
      });

      // Update
      $scope.update = function () {
        $scope.updateStitchesName();
        $scope.updateColorsName();
        $scope.updateGaugesName();
        $scope.updateSecondaryMaterialsName();
      };

      // Update stitches name
      $scope.updateStitchesName = function(item) {
        $scope.item.stitchesResourceName = [];
        $scope.item.stitches.forEach(function(stitch) {
          if($rootScope.resources.stitches.hashmap[stitch.stitch_id]) {
            $scope.item.stitchesResourceName.push({ "name" : $rootScope.resources.stitches.hashmap[stitch.stitch_id].name});
          }

        });
      };

      // Update gauges name
      $scope.updateGaugesName = function() {
        $scope.item.gaugesResourceName = [];
        $rootScope.resources.gauges.data.forEach(function(elem) {
          var check = false;
          $scope.item.gauges.forEach(function(gauge) {
            if (gauge.gauge_id == elem.id) {
              check = true;
            }
          });
          if(check) {
            $scope.item.gaugesResourceName.push({ "name" : elem.name});
          }
        });
      };

      // Update colors name
      $scope.updateColorsName = function() {
        $scope.item.colorsResourceName = [];
        $scope.item.colors.forEach(function(color) {
          if($rootScope.resources.colors.hashmap[color.color_id]) {
            $scope.item.colorsResourceName.push({ "name" : $rootScope.resources.colors.hashmap[color.color_id].name});
          }
        });
      };

      // Update secondary materials name
      $scope.updateSecondaryMaterialsName = function() {
        $scope.item.secondaryMaterialsResourceName = [];
        $scope.item.secondary_materials.forEach(function(secondary_m) {
          if($rootScope.resources.secondaryMaterials.hashmap[secondary_m.secondary_material_id].name) {
            $scope.item.secondaryMaterialsResourceName.push({ "name" : $rootScope.resources.secondaryMaterials.hashmap[secondary_m.secondary_material_id].name});
          }
        });
      };

      //Enable edit
      $scope.activateEdit = function(){
        setGauges();
        setColors();
        setMaterials();
        $scope.editItem = true;
        $rootScope.$emit('edit-mode', true);
      };

      $scope.deactivateEdit = function(){
        formErrorsInit();

        //Restores item
        $scope.sketch_image = [];
        $scope.front_image = [];
        $scope.back_image = [];
        $scope.details_image = [];
        $scope.item = Restangular.copy($scope.savedData.item);
        $scope.update($scope.item);
        $scope.itemCategoryLook = angular.copy($scope.savedData.itemCategoryLook);
        $scope.colorsTags = [];
        $scope.materialsTags = [];
        $scope.editItem = false;
        $rootScope.$emit('edit-mode', false);
      };

      $scope.isValid = true;
      var isValidData = function(){
        formErrorsInit();

        //Required TODO (mancano scollo, manica con boolean)
        $rootScope.$emit('deleteInvalidCouples');

        //Delete empty entries
        var deleteEmpty = ['gauges', 'stitches', 'yarns'];
        for(var prop = 0; prop < deleteEmpty.length; prop++){
          var property = deleteEmpty[prop];
          var property_id = property == 'stitches'? 'stitch_id' : property.slice(0, property.length-1) + '_id';
          for(var i=0; i< $scope.item[property].length; i++){
            if($scope.item[property][i][property_id] == ''){
              $scope.item[property].splice(i,1);
            }
          }
        }
        //Delete empty textile_fibres rows
        for(var f=$scope.item.textile_fibres.length-1; f>= 0; f--){
          if($scope.item.textile_fibres[f].textile_fibre_id == '' && $scope.item.textile_fibres[f].percentage == ''){
            $scope.item.textile_fibres.splice(f,1);
          }
        }

        var errors = {
          gender : $scope.item.gender_id !== '',
          model_code : $scope.item.model_code !== '',
          server_model_code : true,
          article_code : $scope.item.article_code !== '',
          collection_id : $scope.item.collection_id !== '',
          look_categories : $scope.itemCategoryLook.id !== '' && $scope.itemCategoryLook.id !== undefined,
          look : ($scope.item.look_id !== '' && $scope.item.look_id !== undefined),
          percentage : $scope.isTextilePercentageOk(),
          gauges : $scope.item.gauges.length !== 0,
          repeatedCouples : $window.document.getElementsByClassName('item-form__error').length === 0
        };

        $scope.formErrors = errors;

        $scope.isValid = true;
        for(error in errors){
          $scope.isValid = $scope.isValid && errors[error];
        }
        //console.log('Item valido?: ' + $scope.isValid);

        return $scope.isValid;
      };

      $scope.deleteImg = function(which){
        $scope[which + '_image'] = [];
        $scope.item[which + '_image_thumb'] = '';
        $scope.item[which + '_image_medium'] = '';
        $scope.item[which + '_image_original_file_name'] = null;
        $scope.item[which + '_image_destroy'] = 'true';
      };

      $scope.saveItem = function(){
        if(isValidData()){
          $scope.saving = true;

          //Check collection_id
          if($scope.item.collection_id == null || $scope.item.collection_id == 0){
            $scope.item.collection_id = '';
          }

          // plain() remove restangular stuff, angular.copy remove angular $$hashkey
          var cleanItem = angular.copy($scope.item.plain());

          if ($scope.sketch_image && $scope.sketch_image[0]){
            delete cleanItem['sketch_image_destroy'];
            cleanItem.sketch_image = $scope.sketch_image[0];
          }
          if ($scope.front_image && $scope.front_image[0]){
            delete cleanItem['front_image_destroy'];
            cleanItem.front_image = $scope.front_image[0];
          }
          if ($scope.back_image && $scope.back_image[0]){
            delete cleanItem['back_image_destroy'];
            cleanItem.back_image = $scope.back_image[0];
          }
          if ($scope.details_image && $scope.details_image[0]){
            delete cleanItem['details_image_destroy'];
            cleanItem.details_image = $scope.details_image[0];
          }

          var formData = Object.toFormData(cleanItem);

          ItemsService.putItem(formData, $scope.item.id).then(
            function (item) {

              ItemsService.createEmptyCouplesinItem(item);

              $scope.item = Restangular.copy(item);
              $scope.update($scope.item);
              $scope.savedData.item = Restangular.copy(item);
              $scope.savedData.itemCategoryLook = angular.copy($scope.itemCategoryLook);

              $scope.colorsTags = [];
              $scope.materialsTags = [];

              // check if there are errors and whether the user has handled them
              MassUploadService.getErrors().then(function(res) {
                res.data.filter(function (error) { return error.file_id === cleanItem.id }).forEach(function (error) {
                  if (((cleanItem.back_image || cleanItem.back_image_destroy) && error.side === 'r') ||
                    ((cleanItem.details_image || cleanItem.details_image_destroy) && error.side === 'det') ||
                    ((cleanItem.front_image || cleanItem.front_image_destroy) && error.side === 'f') ||
                    ((cleanItem.sketch_image || cleanItem.sketch_image_destroy) && error.side === 'b')) MassUploadService.deleteError(error.id);
                });
                //Close edit
                $scope.editItem = false;
                $scope.saving = false;

                $rootScope.$emit("itemUpdated",
                  {
                    item : item
                  }
                );
              });
            },
            function(errors){
              if(errors.data) $scope.isValid = false;
              $scope.saving = false;
              //Set errors
              for(var k in errors.data){
                $scope.formErrors['server_' + k] = false; //Set error in key
              }

              ItemsService.createEmptyCouplesinItem($scope.item);
            }
          );
          $rootScope.$emit('edit-mode', false);
        }else{ItemsService.createEmptyCouplesinItem($scope.item);}
      };

      //Handle gauges checkbox
      $scope.handleGauges = function(index, value){
        if(value){
          $scope.item.gauges.push({ gauge_id : $scope.gaugesResource.data[index].id});
        }
        else{
          var stop = false;
          for(var i=0; i<$scope.item.gauges.length && !stop; i++){
            if($scope.item.gauges[i].gauge_id == $scope.gaugesResource.data[index].id){
              $scope.item.gauges.splice(i,1);
              stop = true;
            }
          }
        }
      };

      //Textile Fibres
      $scope.isTextilePercentageOk = function(){
        var sum = 0;
        for(var i=0; i< $scope.item.textile_fibres.length; i++){
          if($scope.item.textile_fibres[i].percentage !== ''){
            sum += parseFloat($scope.item.textile_fibres[i].percentage);
          }
        }
        return sum<=100;
      };

      $scope.deleteTextileRow = function(index){
        if($scope.item.textile_fibres.length == 1) {
          $scope.item.textile_fibres[index]['textile_fibre_id'] = '';
          $scope.item.textile_fibres[index]['percentage'] = '';
        } else{
          $scope.item.textile_fibres.splice(index, 1);
        }
      };

      $scope.addTextileRow = function(){
        if($scope.isTextilePercentageOk()){
          var newItem = {
            textile_fibre_id : '',
            percentage: ''
          };
          $scope.item.textile_fibres.push(newItem);
        }
      };

      //Stitches
      $scope.deleteStitchesRow = function(index){
        if($scope.item.stitches.length == 1) {
          $scope.item.stitches[index]['stitch_id'] = '';
        } else{
          $scope.item.stitches.splice(index, 1);
        }
      };

      $scope.addStitchesRow = function(){
        if($scope.item.stitches.length > 0 && $scope.item.stitches[$scope.item.stitches.length-1].stitch_id !== ''){
          var newItem = {
            stitch_id: ''
          };
          $scope.item.stitches.push(newItem);
        }
      };

      //Yarns
      $scope.deleteYarnsRow = function(index){
        if($scope.item.yarns.length == 1) {
          $scope.item.yarns[index]['yarn_id'] = '';
        } else{
          $scope.item.yarns.splice(index, 1);
        }
      };

      $scope.addYarnsRow = function(){
        if($scope.item.yarns.length > 0 && $scope.item.yarns[$scope.item.yarns.length-1].yarn_id !== '') {
          var newItem = {
            yarn_id: ''
          };
          $scope.item.yarns.push(newItem);
        }
      };

      /*Colors
      $scope.loadColorsTags = function(query) {
        return $filter('filter')($rootScope.resources.colors.data, query);
      };*/

      var tagIndexInColors = function(colorId){
        for(var i=0; i< $scope.item.colors.length ; i++){
          if($scope.item.colors[i].color_id == colorId){
            return i;
          }
        }
      };

      $scope.addColorsTag = function(tag, model){
        $scope.item.colors.push({color_id : tag.id});
      };

      $scope.removeColorsTag = function(tag, model){
        var index = tagIndexInColors(tag.id);
        $scope.item.colors.splice(index,1);

      };

      //Secondary materials
      $scope.loadMaterialsTags = function(query) {
        return $filter('filter')($rootScope.resources.secondaryMaterials.data, query);
      };

      var tagIndexInMaterials = function(materialId){
        for(var i=0; i< $scope.item.secondary_materials.length ; i++){
          if($scope.item.secondary_materials[i].secondary_material_id == materialId){
            return i;
          }
        }
      };

      $scope.addMaterialsTag = function(tag){
        $scope.item.secondary_materials.push({secondary_material_id : tag.id});
      };

      $scope.removeMaterialsTag = function(tag){
        var index = tagIndexInMaterials(tag.id);
        $scope.item.secondary_materials.splice(index,1);

      };

      //Check if row is repeated
      $scope.isInvalid = function(model, $index, property){
        if(model[$index][property] == '') return false;
        for(var i=0; i< model.length; i++){
          if(model[i][property] == model[$index][property] && i !== $index){
            return true;
          }
        }
        return false;
      };

      //Delete item from modal 'ok'
      $scope.deleteItem = function(){
        ItemsService.deleteItem($scope.item.id).then(
          function () {
            $state.go('items');
          });
      };

      /*
       <!--on-select="populateLooks($item, $model)"-->
      $scope.populateLooks = function(item, model){
        console.log(item);
        console.log(model);
      };*/

      //Events
      //var resourceReloaded = $rootScope.$on('resource:reloaded', resourceReloaded());
      var resourceLoaded = $rootScope.$on('resource:loaded', function() {init()});

      var unbind = [/*resourceReloaded,*/ resourceLoaded];
      $scope.$on('$destroy', function () {
        unbind.forEach(function (fn) {
          fn();
        });
      });

      //Image modal
      //Warning : Foundation doesn't destroy modals on DOM - a modal is forever
      var itemPhotosModalConfig = function(imageSrc){
        var config = {
          //id: 'item-photos-modal',
          templateUrl: 'templates/item-photos-modal.html',
          contentScope: {
            src : imageSrc
          },
          animationIn: 'slideInFromTop',
          destroy: true
        };
        return config;
      };

      $scope.openImageModal = function(imageSrc){
        $scope.modal = new ModalFactory(itemPhotosModalConfig(imageSrc));
        $scope.modal.activate();
        FoundationApi.publish('item-photos-modal', 'open');
      };

      $scope.getFileSrc = function(e){
        return e.target.src;
      };

      $scope.onNecklineSelection = function(neckline) {
        eraseNecklinePosition();
        if(neckline) {
          toggleNecklinePos(true);
        }
        else {
          toggleNecklinePos(false);
        }
      };

      function eraseNecklinePosition() {
        $scope.item.neckline_position_id = null;
      };

      function toggleNecklinePos(enabled) {
        $scope.necklinePosEnabled = enabled;
      };
    }
  ]);



