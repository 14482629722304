angular
  .module('directives')
  .directive('itemsRadioFilter', itemsRadioFilter);

  itemsRadioFilter.$inject = [];
  function itemsRadioFilter () {
    var itemsRadioFilterDirective = {
      restrict: 'E',
      scope: {
        filterModel : '=', // filterObj.prop
        title: '@',
        stateCatalogue: '='
      },
      templateUrl: '../../templates/items-radio-filter.html',

      link: function (scope, elem, attrs) {
        scope.show = false;

        scope.changeRadio = function(value){
            scope.filterModel = value;
        };

        scope.clearFilter = function(){
          scope.filterModel = null;
        };
      }
    };
    return itemsRadioFilterDirective;
  }

