angular
  .module('directives')
  .directive( 'changeLineDimension', function($rootScope, $timeout,$window) {
    return {
      scope : {},
      link: function( scope, element, attrs ) {
        element.ready(function(){
          scope.$watch(function(){return element[0].children.length;}, function(newVal, oldVal){
            if(newVal != oldVal && newVal !== undefined) {
              $timeout(function() {
                var width = element.width();
                var childWidth = 0;
                var number = 1;
                for (var i = 0; i < element[0].children.length; i++) {
                  childWidth = childWidth + angular.element(element[0].children[i]).outerWidth( true ); // dimension of children
                  if(childWidth > width) {
                    number ++;
                    childWidth = angular.element(element[0].children[i]).outerWidth( true );
                  }
                }
                if(number > 1) {
                  element.parent().css({"height": ((40 * number) - (7 * (number - 1))) + "px"});
                }
                else {
                  element.parent().css({"height": "40px"});
                }
              });
            }
          },true);

          scope.changeDimensionLine = function () {
            var width = element.width();
            var childWidth = 0;
            var number = 1;
            for (var i = 0; i < element[0].children.length; i++) {
              childWidth = childWidth + angular.element(element[0].children[i]).outerWidth( true ); // dimension of children
              if(childWidth > width) {
                number ++;
                childWidth = angular.element(element[0].children[i]).outerWidth( true );
              }
            }
            if(number > 1) {
              element.parent().css({"height": ((40 * number) - (7 * (number - 1))) + "px"});
            }
            else {
              element.parent().css({"height": "40px"});
            }
          };

          var editMode = $rootScope.$on('edit-mode', function(event, value) {
            $timeout(function() {
              if(!value || attrs.changeLineDimension=="editModeToo") {
                scope.changeDimensionLine();
              }
            });
          });
          var unbind = [editMode];
          scope.$on('$destroy', function () {
            unbind.forEach(function (fn) {
              fn();
            });
          });

          angular.element($window).bind('resize', function(){
            scope.changeDimensionLine();
            scope.$digest();
          });


        });
      }
    }
  } );
