angular.module('application')
    .config(['$translateProvider', function ($translateProvider) {
        $translateProvider.translations('it',
            {
                "LANGUAGE": "it",
                "LANGUAGE-TEXT": "Italiano",

                "header": {
                    "items": "Archivio",
                    "catalogue": "Catalogo",
                    "mass-upload": "Upload"
                },

                "resources":{
                  "adornment": "Applicazione",
                  "bottomItemLenghts" : "Lunghezza Inferiore",
                  "closures": "Chiusure",
                  "closurePositions": "Posizioni chiusure",
                  "collections" : "Collezioni",
                  "clothingBrand" : "Brand",
                  "customer": "Cliente",
                  "season" : "Stagione",
                  "color" : "Colore",
                  "decorationPattern" : "Decorazione",
                  "decorationPatternCategories" : "Categorie Decorazioni",
                  "decorationPositions" : "Posizione Decorazioni",
                  "fittings" : "Vestibilità",
                  "finishing": "Finissaggio",
                  "finishingCategories": "Categorie Finissaggi",
                  "garmentAccessories" : "Accessori",
                  "garmentAccessoryCategories": "Categorie Accessori",
                  "garmentAccessoryPositions": "Posizione Accessori",
                  "gauge" : "Finezza",
                  "genders": "Genere",
                  "lengths": "Lunghezze",
                  "necklines": "Scolli",
                  "necklineCategories": "Categorie Scolli",
                  "necklinePositions": "Posizione Scollo",
                  "pockets" : "Tasche",
                  "pocketsPositions": "Posizione Tasche",
                  "secondaryMaterial": "Materiale Secondario",
                  "secondaryMaterialCategories": "Categorie Materiali Secondari",
                  "sleeves": "Maniche",
                  "sleeveLengths": "Lunghezza Maniche",
                  "sleeveWidths": "Ampiezza Maniche",
                  "stitch": "Punto",
                  "stitchCategories": "Categorie Punti",
                  "textileFibre": "Composizione",
                  "topItemLengths": "Lunghezza superiore",
                  "look": "Tipologia",
                  "lookCategories": "Categorie Tipologie",
                  "yarn": "Filato",
                  "year": "Anno"
                },

                "landing" : {
                  "email" : "email",
                  "password" : "password",
                  "login" : "Login",
                  "forgot" : "forgot password",
                  "mobile-text": "La piattaforma è raggiungibile da pc o da tablet"
                },

                "forgot" : {
                  "email": 'email',
                  "request" : "Richiedi il reset password"
                },

                "catalogue" : {
                  "filter" : "Filtra",
                  "no-results": "Non ci sono risultati per i filtri di ricerca impostati."
                },
                "catalogue-item" : {
                  "back" : "Indietro",
                  "detail": "Dettaglio"
                },
                "items" : {
                  "new-item" : "Nuovo capo",
                  "search" : "Ricerca",
                  "filter" : "Filtra",
                  "model-id" : "ID Modello",
                  "article-id" : "ID Articolo",
                  "no" : "No",
                  "yes" : "Yes",
                  "select": "Seleziona",
                  "no-results": "Non ci sono risultati per i filtri di ricerca impostati."
                },
                "item": {
                  "new-item": "Nuovo capo",
                  "image" : "Immagine",
                  "look_category" : "Categoria tipologia",
                  "revised" : "Revisionato",
                  "catalogue" : "Catalogo",
                  "delete-modal-text" : "Sicuro di voler eliminare il capo?",
                  "delete-modal-no" : "No",
                  "delete-modal-yes" : "Si",
                  "modify-item": "Modifica capo",
                  "verify": "Verifica gli errori",
                  "cancel": "Annulla",
                  "save": "Salva",
                  "delete": "Elimina",
                  "modify": "Modifica",

                  "sketch": "Bozza",
                  "front": "Fronte",
                  "back": "Retro",
                  "detail": "Dettaglio",

                  "img-attach" : "Aggiungi immagine",
                  "img-delete": "Elimina immagine",

                  "published" : "Pubblicato",
                  "yes": "Si",
                  "no": "No",

                  "delete-row": "Elimina",
                  "add-row": "Aggiungi",

                  "define-pos": "Specifica Posizione",
                  "define-type": "Specifica Tipo",

                  "model-error" : "Articolo già presente, verificare modello, articolo e collezione",
                  "gender" : "*Genere",
                  "model": "*Modello",
                  "article": "*Articolo",
                  "collection": "*Collezione",
                  "client": "Cliente",
                  "line": "Linea",
                  "look":"*Tipologia",
                  "lookCategories": "*Categorie tipologia",
                  "season": "Stagione",
                  "season-code" : "Codice Stagione",
                  "year": "Anno",
                  "prototype": "Prototipo",
                  "item-description": "Descrizione Capo",
                  "fitting": "Vestibilità",
                  "length": "Lunghezza",
                  "neckline": "Scollo",
                  "neckline-pos": "Posizione Scollo",
                  "sleeve": "Manica",
                  "sleeve-len": "Lunghezza Manica",
                  "sleeve-wid": "Ampiezza Manica",
                  "pockets-pos": "Tasche/Posizione",
                  "closures-pos": "Chiusure/Posizione",
                  "garments-pos": "Accessori/Posizione",
                  "manufacture": "Lavorazione",
                  "materials": "Materiali",
                  "stitches": "Punti",
                  "gauges": "*Finezze",
                  "decorations": "Decorazioni",
                  "patterns-pos": "Motivi/Posizione",
                  "adornments-pos": "Applicazioni/Posizione",
                  "finishings-pos": "Finissaggi/Posizione",
                  "textile-fibres": "Composizione",
                  "yarns": "Filati",
                  "secondary-materials": "Materiali secondari",
                  "colors": "Colori"
                },
                "upload": {
                  "file-name": "Nome file",
                  "last-upload-date": "Data ultimo caricamento",
                  "feedback": "Feedback",
                  "new-upload": "Nuovo caricamento",
                  "discard": "Annulla",
                  "confirm": "Conferma",
                  "loading": "Caricamento in corso",
                  "not-found": "Scheda capo non trovata",
                  "not-valid": "Formato nome non valido",
                  "photo-type-not-valid": "Nome foto non valido",
                  "error": "Errore",
                  "errors": "Errori",
                  "already-uploaded": "Foto già presente",
                  "success": "Successo",
                  "images-loaded": "immagini caricate",
                  "images-not-loaded": "immagini non caricate",
                  "upload-results": "Caricamento completato",
                  "no-errors": "Nessun errore",
                  "upload_folder": "Carica una cartella",
                  "upload_files": "Carica file",
                  "no-filtered-errors": "Non ci sono risultati per i filtri di ricerca impostati"
                }
            }
        );

        $translateProvider.translations('en',
            {
                "LANGUAGE": "en",
                "LANGUAGE-TEXT": "English",
                "header": {
                    "items": "Archive",
                    "catalogue": "Catalogue",
                    "mass-upload": "Upload"
                },

                "resources":{
                  "adornment": "Adornment",
                  "bottomItemLenghts" : "Bottom Item Lenghts",
                  "closures": "Closures",
                  "closurePositions": "Closure Positions",
                  "collections" : "Collections",
                  "clothingBrand" : "Clothing Brand",
                  "customer": "Customer",
                  "season" : "Season",
                  "color" : "Color",
                  "decorationPattern" : "Decoration Pattern",
                  "decorationPatternCategories" : "Decoration Pattern Categories",
                  "decorationPositions" : "Decoration Positions",
                  "fittings" : "Fittings",
                  "finishing": "Finishing",
                  "finishingCategories": "Finishing Categories",
                  "garmentAccessories" : "Garment Accessories",
                  "garmentAccessoryCategories": "Garment Accessory Categories",
                  "garmentAccessoryPositions": "Garment Accessory Positions",
                  "gauge" : "Gauge",
                  "genders": "Genders",
                  "lengths": "Lengths",
                  "necklines": "Necklines",
                  "necklineCategories": "Neckline Categories",
                  "necklinePositions": "Neckline Positions",
                  "pockets" : "Pockets",
                  "pocketsPositions": "Pockets Positions",
                  "secondaryMaterial": "Secondary Material",
                  "secondaryMaterialCategories": "Secondary Material Categories",
                  "sleeves": "Sleeves",
                  "sleeveLengths": "Sleeve Lengths",
                  "sleeveWidths": "Sleeve Widths",
                  "stitch": "Stitch",
                  "stitchCategories": "Stitch Categories",
                  "textileFibre": "Textile Fibre",
                  "topItemLengths": "Top Item Lengths",
                  "look": "Look",
                  "lookCategories": "Look Categories",
                  "yarn": "Yarn",
                  "year": "Year"
                },

                "landing" : {
                  "email" : "email",
                  "password" : "password",
                  "login" : "Login",
                  "forgot" : "forgot password",
                  "mobile-text": "The app is available for desktop and tablet"
                },

                "forgot" : {
                  "email": 'email',
                  "request" : "Request password reset"
                },

                "catalogue" : {
                  "filter" : "Filter",
                  "no-results": "There are no results for the search filters set."
                },
                "catalogue-item" : {
                  "back" : "Back",
                  "detail": "Details"
                },
                "items" : {
                  "new-item" : "New Item",
                  "search" : "Search",
                  "filter" : "Filter",
                  "model-id" : "Model ID",
                  "article-id" : "Article ID",
                  "no" : "No",
                  "yes" : "Yes",
                  "select": "Select",
                  "no-results": "There are no results for the search filters set."
                },
                "item": {
                  "new-item": "New item",
                  "image": "Image",
                  "look_category" : "Has look category",
                  "revised" : "Revised",
                  "catalogue" : "Catalogue",
                  "delete-modal-text" : "Are you sure?",
                  "delete-modal-no" : "No",
                  "delete-modal-yes" : "Yes",
                  "modify-item": "Modify item",
                  "verify": "Verify errors",
                  "cancel": "Cancel",
                  "save": "Save",
                  "delete": "Delete",
                  "modify": "Modify",

                  "sketch": "Sketch",
                  "front": "Front",
                  "back": "Back",
                  "detail": "Detail",

                  "img-attach" : "Attach an image",
                  "img-delete": "Delete image",

                  "published" : "Published",
                  "yes": "Yes",
                  "no": "No",

                  "delete-row": "Delete",
                  "add-row": "Add",

                  "define-pos": "Define Position",
                  "define-type": "Define Type",

                  "model-error" : "Article already exists, verify article, model and collection",

                  "gender" : "*Gender",
                  "model": "*Model",
                  "article": "*Article",
                  "collection": "*Collection",
                  "client": "Client",
                  "line": "Line",
                  "look":"*Look",
                  "lookCategories": "*Look categories",
                  "season": "Season",
                  "season-code" : "Season Code",
                  "year": "Year",
                  "prototype": "Prototype",
                  "item-description": "Item Description",
                  "fitting": "Fitting",
                  "length": "Length",
                  "neckline": "Neckline",
                  "neckline-pos": "Neckline Position",
                  "sleeve": "Sleeve",
                  "sleeve-len": "Sleeve length",
                  "sleeve-wid": "Sleeve width",
                  "pockets-pos": "Pockets/Position",
                  "closures-pos": "Closures/Position",
                  "garments-pos": "Garments/Position",
                  "manufacture": "Manufacture",
                  "materials": "Materials",
                  "stitches": "Stitches",
                  "gauges": "*Gauges",
                  "decorations": "Decorations",
                  "patterns-pos": "Patterns/Position",
                  "adornments-pos": "Adornments/Position",
                  "finishings-pos": "Finishings/Position",
                  "textile-fibres": "Textile",
                  "yarns": "Yarns",
                  "secondary-materials": "Secondary Materials",
                  "colors": "Colors"
                },
                "upload": {
                  "file-name": "File name",
                  "last-upload-date": "Last upload date",
                  "feedback": "Feedback",
                  "discard": "Discard",
                  "confirm": "Confirm",
                  "new-upload": "New upload",
                  "loading": "Uploading",
                  "not-found": "Item not found",
                  "not-valid": "Photo name not valid",
                  "photo-type-not-valid": "Photo name not valid",
                  "error": "Error",
                  "errors": "Errors",
                  "already-uploaded": "Already uploaded",
                  "success": "Success",
                  "images-loaded": "images loaded",
                  "images-not-loaded": "images not loaded",
                  "upload-results": "Upload completed",
                  "no-errors": "No errors",
                  "upload_folder": "Upload a folder",
                  "upload_files": "Upload files",
                  "no-filtered-errors": "No results for the selected search filters"
                }

            }
        );
    }])
  .config(function($provide) {
    $provide.decorator('translateDirective', function($delegate) {
      var directive = $delegate[0];
      directive.terminal = true;

      return $delegate;
    });
  });
